import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import ScrollToTop from 'components/ScrollToTop';

const { Paragraph, Title } = Typography;

import founderRosana from 'assets/images/founders/founderRosana.png';
import founderLeticia from 'assets/images/founders/founderLeticia.png';

import './styles.scss';

const AboutUs = () => {
  const { t } = useTranslation();

  const contentaDifference = [
    t('aboutUsV2.difference.realExperts'),
    t('aboutUsV2.difference.personalizedRecommendations'),
    t('aboutUsV2.difference.saveTime'),
    t('aboutUsV2.difference.videoChat'),
  ];

  const howItWorksSteps = [
    {
      title: t('aboutUsV2.howItWorks.steps.1.title'),
      text: t('aboutUsV2.howItWorks.steps.1.text'),
    },
    {
      title: t('aboutUsV2.howItWorks.steps.2.title'),
      text: t('aboutUsV2.howItWorks.steps.2.text'),
    },
    {
      title: t('aboutUsV2.howItWorks.steps.3.title'),
      text: t('aboutUsV2.howItWorks.steps.3.text'),
    },
    {
      title: t('aboutUsV2.howItWorks.steps.4.title'),
      text: t('aboutUsV2.howItWorks.steps.4.text'),
    },
  ];

  const whyChoose = [
    t('aboutUsV2.why.humanExperience'),
    t('aboutUsV2.why.tailoredToYou'),
    t('aboutUsV2.why.saveTime'),
    t('aboutUsV2.why.travelLikeLocal'),
    t('aboutUsV2.why.trustTransparency'),
  ];

  return (
    <div className="about-us">
      <ScrollToTop />
      <Title className="about-us__title" level={2}>
        {t('aboutUsV2.title')}
      </Title>
      <Divider />
      <Title level={3}>{t('aboutUsV2.whoWeAre.title')}</Title>
      <p>{t('aboutUsV2.whoWeAre.p1')}</p>
      <p>{t('aboutUsV2.whoWeAre.p2')}</p>
      <Divider />
      <Title level={3}>{t('aboutUsV2.mission.title')}</Title>
      <p>{t('aboutUsV2.mission.p1')}</p>
      <p>{t('aboutUsV2.mission.p2')}</p>
      <Divider />
      <Title level={3}>{t('aboutUsV2.difference.title')}</Title>
      <ul>
        {contentaDifference.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </ul>
      <p dangerouslySetInnerHTML={{ __html: t('aboutUsV2.humanTouch') }} />
      <Divider />
      <Title level={3}>{t('aboutUsV2.howItWorks.title')}</Title>
      {howItWorksSteps.map((step, index) => (
        <div key={index} style={{ marginBottom: '24px' }}>
          <Paragraph strong style={{ fontSize: '16px', margin: '0' }}>
            {step.title}
          </Paragraph>
          <p
            style={{ fontSize: '16px', margin: '0' }}
            dangerouslySetInnerHTML={{ __html: step.text }}
          />
        </div>
      ))}
      <Divider />
      <Title level={3}>{t('aboutUsV2.founders.title')}</Title>
      <div className="founders">
        <div className="founder">
          <img src={founderRosana} alt="Rosana Grilli" />
          <p>
            <strong>Rosana Grilli</strong>
            Founder
          </p>
        </div>

        <div className="founder">
          <img src={founderLeticia} alt="Leticia Arzuaga" />
          <p>
            <strong>Leticia Arzuaga</strong>
            Co-Founder
          </p>
        </div>
      </div>

      <p className="founder-name">{t('aboutUsV2.founders.rosana.bio')}</p>
      <div
        className="founder-description"
        dangerouslySetInnerHTML={{
          __html: t('aboutUsV2.founders.rosana.description'),
        }}
      />

      <br />
      <p className="founder-name">{t('aboutUsV2.founders.leticia.bio')}</p>
      <div
        className="founder-description"
        dangerouslySetInnerHTML={{
          __html: t('aboutUsV2.founders.leticia.description'),
        }}
      />

      <Divider />
      <Title level={3}>{t('aboutUsV2.why.title')}</Title>
      <ul>
        {whyChoose.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </ul>
    </div>
  );
};

export default AboutUs;
