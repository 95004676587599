export default {
  translations: {
    common: {
      avaliation: 'Rating',
      unexpectedError: 'An unexpected error occurred',
      counponExtraValue:
        'Voucher applied successfully! Your voucher covered 100% of the value and you will still have a credit of {AMOUNT_CREDIT}! We will send a new voucher with the remaining amount to you via email!',
      couponExaclyValue:
        'Voucher applied successfully! Your voucher covers 100% of the cost!',
      couponMissingValue: 'Voucher applied successfully!',
      comeBackAndFinish: 'Hey, come back and finish your booking!',
      noRate: 'No reviews yet',
      select: 'Select an option',
      notInformed: 'Not informed',
      addPhoto: 'Add photo',
      address: 'Address',
      answer: 'Answer',
      back: 'Back',
      betaVersion: 'beta version',
      economic: 'Economic',
      cancel: 'Cancel',
      canceled: 'Canceled',
      suspended: 'Suspended',
      close: 'Close',
      confirm: 'Confirm',
      contentaPhraseLogo: 'smart online travel planning, unique private tours',
      continue: 'Continue',
      create: 'Create',
      delete: 'Delete',
      de: 'of',
      edit: 'Edit',
      find: 'Search',
      bySpecialty: 'by specialty',
      finish: 'Finish',
      hello: 'Hi',
      hour: 'Hour',
      hours: 'Hours',
      idioms: 'Languages',
      inPerson: 'In-person',
      loading: 'Loading',
      logout: 'Logout',
      refuseds: 'Refuseds',
      register: 'Register',
      remove: 'Remove',
      requestChange: 'Request change',
      reschedule: 'Reschedule',
      save: 'Save',
      saving: 'Saving',
      selfRegister: 'Register',
      send: 'Send',
      searchPlace: 'Search for a place...',
      success: 'Success',
      successUpdate: 'Successfully updated',
      tryAgain: 'Try again',
      underConstruction: 'Under construction',
      underConstructionSubTitle: 'We are working on it',
      noAvailableSchedules: 'No availability found.',
      update: 'Update',
      upload: 'Upload',
      understand: 'Understood',
      virtual: 'Virtual',
      traveller: 'Traveler',
      advisor: 'Advisor',
      didntShowUp: 'didn’t show up',
      you: 'You',
      traveler: 'Traveler',
      advisorPhrase: 'Advisor phrase',
      showMore: 'Show more',
      showLess: 'Show less',
      languages: {
        'PT-BR': 'Portuguese',
        'pt-BR': 'Portuguese',
        'en-US': 'English',
        es: 'Spanish',
        en: 'English',
      },
      error: {
        generic: 'An error occurred',
        errorFetching: 'Error fetching data',
        errorFetchingFutureSchedules: 'Could not load future schedules',
        errorFetchingPastSchedules: 'Could not load past schedules',
        errorFetchingAdvisors: 'Could not load Advisors',
        createSchedule: 'Could not create the schedule',
        newsletter: 'Could not subscribe to the newsletter',
        under18: 'You must be over 18 years old',
        rescheduleMeeting: 'Could not reschedule the meeting',
        submitResume: 'Could not send the summary',
        joinMeetingFailed: 'Could not join the meeting',
        failedResendEmail: 'Could not resend email',
        cookiesDisabled: 'Cookies are disabled',
        serverError: 'Server error',

        enableCookies: {
          title: 'How to Enable Cookies',
          chrome: {
            step1: 'In Google Chrome.',
            step2:
              'Click on the three vertical dots in the upper right corner and select "Settings".',
            step3: 'In the left menu, click on "Privacy and security".',
            step4: 'Click on "Cookies and other site data".',
            step5:
              'Make sure "Allow all cookies" is selected or that "Block third-party cookies" is not enabled.',
            step6: 'Close the settings tab.',
          },
          firefox: {
            step1: 'In Mozilla Firefox.',
            step2:
              'Click on the three horizontal lines in the upper right corner and select "Settings".',
            step3: 'In the left menu, click on "Privacy and Security".',
            step4:
              'Under "Cookies and Site Data", ensure "Accept cookies and site data" is selected.',
            step5: 'Close the settings tab.',
          },
          edge: {
            step1: 'In Microsoft Edge.',
            step2:
              'Click on the three horizontal dots in the upper right corner and select "Settings".',
            step3: 'In the left menu, click on "Cookies and site permissions".',
            step4: 'Click on "Manage and delete cookies and site data".',
            step5:
              'Make sure "Allow sites to save and read cookie data" is enabled.',
            step6: 'Close the settings tab.',
          },
          safari: {
            step1: 'In Safari.',
            step2:
              'Click on "Safari" in the menu bar and select "Preferences".',
            step3: 'Click on the "Privacy" tab.',
            step4: 'Ensure "Prevent cross-site tracking" is unchecked.',
            step5: 'Close the preferences window.',
          },
          finalNote:
            'After following these steps, please reload the page. If you encounter any issues, feel free to check your browser’s support documentation.',
        },
      },
      successMessages: {
        createSchedule: 'Schedule created successfully',
        submitResume: 'Summary sent successfully',
      },
    },
    login: {
      user: 'User',
      password: 'Password',
      login: 'Login',
      loginAsAdvisor: 'Login as a <br />Travel Expert',
      loginAsTraveler: 'Login as a <br />Traveler',
      forgotPassword: 'Forgot password?',
      createAccount: 'Create an account',
      loginGoogle: 'Login with Google',
      slogan: '_the world is ours.',
      sloganTraveler: '_the world is yours, travel without limits.',
      confirmEmail: 'Please confirm your email!',
      confirmPassword: 'Confirm password',
      passwordsDoNotMatch: 'Passwords do not match!',
      usernameRequired: 'The email is required',
      passwordRequired: 'The password is required',

      passwordNumber: 'The password must contain at least one number',
      passwordSpecialChar:
        'The password must contain at least one special character',
      passwordMinLength: 'The password must have at least 8 characters',
      passwordUppercase:
        'The password must contain at least one uppercase letter',

      or: 'Or',
      slidesTraveler: {
        slide1: {
          title: 'Spending 60+ hours on travel planning?',
          description:
            'Join Contenta360 and make the most of your time, and of your travel.',
        },
        slide2: {
          title: 'Overwhelmed by 50+ travel websites?',
          description:
            'Make things easy! At Contenta360, get fast, personalized advice.',
        },
        slide3: {
          title: 'Tired of the same old standard tours?',
          description:
            'Our travel experts will craft a unique private tour, tailored just for you.',
        },
      },
      slidesAdvisor: {
        slide1: {
          title: 'Get Paid on & off the field!',
          description:
            'Travelers book 1:1 LIVE video chats for personalized travel planning.',
        },
        slide2: {
          title: 'Work Remotely.',
          description:
            'You are paid per hour to provide expert and reliable advice.',
        },
        slide3: {
          title: 'Repeat.',
          description:
            'Travelers can book multiple sessions for ongoing travel planning.',
        },
        slide4: {
          title: 'In-Person Tours',
          description: 'Travelers can also book you for local guided tours.',
          content: {
            title: 'You are welcome to join our team if you are a:',
            bulletPoints: {
              1: 'Senior licensed tour guide.',
              2: 'Local expert who is creative, innovative, and skilled in customizing travel plans and tours.',
              3: 'Passionate about engaging with people and valuing diversity.',
              4: 'Able to maintain a reliable internet connection and have a quiet, stable space for client calls.',
              5: 'Committed to delivering memorable travel experiences to our travelers.',
            },
          },
        },
      },
    },
    notVerified: {
      title: 'Verify your email!',
      traveller: {
        description: `<p>We sent an email to <strong>{EMAIL}</strong>. Click on the link to verify your account.</p>`,
      },
      advisor: {
        description: `<p>We sent an email to <strong>{EMAIL}</strong>. Click on the link to verify your account.</p>
        <p>Verification is required for you to enjoy all the features of the platform and appear as a Travel Expert to travelers.</p>`,
      },
      resend: 'Resend email',
      resendSuccess: 'Email resent successfully!',
      resendError: 'Could not resend email.',
      alreadyVerified: 'I have verified',
    },

    giftCardModal: {
      tile: 'Gift Card',
      buttonPayment: 'Go to details',
      buttonSchedule: 'Go to purchase details',
      buttonChooseOption: 'Select Travel Expert',
      name: 'Name of the gifted',
      email: 'Email of the gifted',
      message: 'Send a message',
      giftCardData: 'Gifted data',
      scheduleData: 'Schedule',
      desiredValue: 'Desired value',
      sendDate: 'Gift card send date',
      wantChooseAdvisor: 'I want to choose the Travel Expert',
      wantUserChoose: 'I want ${USER} to choose',
      giftedName: 'Gifted',
    },
    home: {
      title: 'Want to receive the best travel tips?',
      subtitle: 'Find travel guides specialized in your travel style.',
      firstInput: {
        label: 'Where?',
        placeholder: 'What are your plans?',
      },
      secondInput: {
        label: 'What experiences are you looking for?',
        placeholder: 'What experiences are you looking for?',
      },
      giftText: 'Give the gift of travel',
      giftButton: 'Gift Card',
      notTalked: '¿',
      letsStart: "Haven't spoken to our guides yet?",
      newSchedulle: 'New schedule',
      banner: {
        contenta: '_ The Contenta 360º',
        paragraph:
          '<p>Connect with our Travel Experts who live in the destination you want to visit. Our Travel Experts are local tour guides and professional experts.</p><p>They are ready to assist you in all stages of planning your trip; from virtual pre-planning meetings to in-person visits.</p>',
        buttonKnow: 'Learn more',
        title:
          'Are you a tour guide? Discover how to become a <strong>Travel Expert</strong>!',
        beAdvisor: 'Be a Travel Expert',
      },
      countryNotInformed: 'Country not informed',
      carousel: {
        one: {
          title: 'Plan smarter!',
          subtitle: 'Tailored itineraries, crafted by locals.',
        },
        two: {
          title: 'Chat. Plan. Travel.',
          subtitle: 'Meet your guide online, explore together in person.',
        },
        three: {
          title: 'Travel like a local!',
          subtitle: 'Expert advice for an adventure like no other.',
        },
        four: {
          title: 'Plan smarter. Travel better!',
          subtitle: 'Tailored itineraries, crafted by locals.',
        },
        five: {
          title: 'Real Human Connection.',
          subtitle: 'Stress-free travel, unforgettable adventures.',
        },
      },
      comments: {
        title: 'What they say about us',
        buttonSeeMore: '+ See more testimonials',
      },
    },
    CSV: {
      checkData: 'Check data',
      advisorName: 'Name',
      advisorSurname: 'Last name',
      advisorEmail: 'Email',
      advisorNationality: 'Nationality',
      advisorSummary: 'Sumary',
      advisorBirthDate: 'Birth Date',
      advisorMobilePhone: 'Cell phone number',
      advisorPhone: 'Phone number',
      languageName: 'Language',
      customLanguageLanguage: 'Other languages',
      consultingRegionName: 'Region',
      consultingRegionCountry: 'Country',
      consultingRegionState: 'State',
      consultingRegionCity: 'City',
      consultingRegionDetails: 'Detail',
      companyName: 'Company name',
      companyDocument: 'Company legal entity',
      companyPosition: 'Position in the company',
      classAssociationName: 'Regulatory Body or Association name',
      classAssociationRegisterNumber: 'Registration number',
      classAssociationAssociatedSince: 'Associated since',
      addressCountry: 'Country',
      addressState: 'State',
      addressCity: 'City',
      addressStreet: 'Address 1',
      addressZipcode: 'Zip code',
      addressComplement: 'Address 2',
      specialties: 'Specialties',
      customSpecialty: 'Other specialities',
      createdAt: 'Created',
      updatedAt: 'Updated',
      currency: 'Currency',
      valueHour: 'Pricing per worked hour you charge',
    },
    guides: {
      bankNameRequired: 'Bank name is required',
      swiftCodeRequired: 'SWIFT code is required',
      ibanRequired: 'IBAN code is required',
      bankNumberRequired: 'Bank number is required',
      agencyNumberRequired: 'Agency number is required',
      accountNumberRequired: 'Account number is required',
      checkData: 'Check data before continuing',
      socialName: 'Social name',
      register: 'Travel Expert Register',
      associatedDay: 'Licensed since',
      alreadyAccount: 'Already have an account?',
      enterHere: 'Enter here',
      step: 'Step',
      step1: 'Personal information',
      step2: 'Professional information',
      step3: 'About you',
      step4: 'Your specialties',
      step5: 'User and password',
      attentionText2:
        'Your Travel Expert account will be available after an interview with Contenta360.',
      registerSuccess1: 'Thank you for registering with Contenta360.',
      registerSuccessParagraph: `<p>We value quality and safety in each experience we offer. That's why the next step is an interview with our team, an opportunity to get to know each other better and understand your work.</p>
      <p><strong>ATTENTION</strong>: Your Travel Expert account will only be available after this conversation with the Contenta360 team.</p>
      <p>Click the button below to schedule the best time for you.</p>`,
      clickHere: 'clicking here',
      afterRegister:
        'After scheduling your interview with our team, click below to access your profile.',
      registerSuccess:
        'Successfully registered. Thanks! We will contact you very soon.',
      name: 'First name',
      nameRequired: 'Required',
      surname: 'Last name',
      surnameRequired: 'Required',
      birthday: 'Birth Date',
      day: 'Day',
      dayRequired: 'Required',
      month: 'Month',
      monthRequired: 'Required',
      year: 'Year',
      yearRequired: 'Required',
      ddi: 'Country code',
      ddiRequired: 'Country code',
      mobilePhone: 'Cell phone number',
      mobilePhoneRequired: 'Required',
      nationality: 'Country of Birth',
      nationalityRequired: 'Required',
      residenceCountry: 'Country of Residence ',
      residenceCountryRequired: 'Required',
      fullAddress: 'Full address',
      zipcode: 'Zip code',
      zipcodeRequired: 'Required',
      state: 'State',
      stateRequired: 'Required',
      city: 'City',
      cityRequired: 'Código postal',
      street: 'Address 1',
      streetRequired: 'Required',
      complement: 'Address 2',
      neighborhood: 'Neighborhood',
      neighborhoodRequired: 'Required',
      specialties: 'At this location, what areas are you specialized in?',
      chooseUpToFiveOptions: 'Choose up to 5 options',
      chooseUpToFiveOptionsLimit: 'Limited to 5 options',
      chooseUpToFiveOptionsRequired: 'Campo Requerido',
      customSpecialty: 'Other',
      tellAboutYourHistory:
        'What is your story, why are you passionate about what you do?',
      tellAboutYourHistoryDesc:
        'In one of the languages you master, tell us a little about yourself. Ex: your time of formation and professional experience, your specialties in the region where you work, number of customers attended.',
      tellAboutYourHistoryRequiOured: 'Required',
      tellAboutYourHistoryCharLimit: 'Limited to 2500 characters',
      association: 'Regulatory Body or Association name',
      associationRequired: 'Required',
      registerNumber: 'License Number',
      registerNumberRequired: 'Required',
      number: 'Number',
      associatedDayRequired: 'Required',
      valueHour: ' Net Hourly Wage You Charge',
      valueHourRequired: 'Required',
      numberRequired: 'Number is required',
      videoCallPrice: 'Video call',
      presentialTour: 'In-Person tour',
      internationalPurchase: 'International purchase',
      packageValue: 'Package Value',
      investmentValue: 'Investment Value',
      currency: 'Currency',
      currencyPlaceholder: 'Select currency',
      currencyRequired: 'Currency is required',
      companyName: 'Company Name',
      companyNameRequired: 'Required',
      companyDocument: 'Taxpayers Identification Number',
      companyDocumentRequired: 'Required',
      companyRole: 'Position',
      companyRoleRequired: 'Required',
      registerTuristGuide:
        'Tour Guide Registration and or  Professional Registration',
      isRequiredLicense:
        'Is it mandatory to have a tourist guide license in the country where you work?',
      chooseAOption: 'Choose an option',
      chooseAOptionRequired: 'Required',
      yes: 'Yes',
      no: 'No',
      depends: 'Depends',
      monthJanuary: 'January',
      monthFebruary: 'February',
      monthMarch: 'March',
      monthApril: 'April',
      monthMay: 'May',
      monthJune: 'June',
      monthJuly: 'July',
      monthAugust: 'August',
      monthSeptember: 'September',
      monthOctober: 'October',
      monthNovember: 'November',
      monthDecember: 'December',
      country: 'Country',
      countryRequired: 'Required',
      aboutDestination: 'About the tourist destination where you work.',
      consultancyRegion: 'Region where you guide or work',
      consultancyDetails:
        'Write something interesting about the region where you guide or work in and area of knowledge',
      languages: 'Languages',
      languageRequired: 'Language is required',
      otherLanguages: 'Other languages',
      photo: 'Photo',
      photoIsRequired: 'Photo is required',
      email: 'E-mail',
      mustBeAdult: 'You must be 18 years old or older',
      invalidDate: 'Invalid date',
      registerNumberDetails: 'Please detail',
      registerNumberDetailsRequired: 'Field is required',
      profilePhrase:
        'Tell us, in a sentence, why you are passionate about your profession. This will be the phrase of your profile on the Contenta360 homepage.',
      profilePhraseRequired: 'Required',
      zipcodeError: 'Zip code not found',
      empty: 'No advisor found with the selected filters',
    },
    requiredField: 'Required field',
    languages: {
      german: 'German',
      english: 'English',
      korean: 'Korean',
      spanish: 'Spanish',
      french: 'French',
      italian: 'Italian',
      italiano: 'Italian',
      japanese: 'Japanese',
      portuguese: 'Portuguese (Portugal)',
      portugueseBrazil: 'Portuguese (Brazil)',
      code: {
        DE: 'German',
        IT: 'Italian',
        KO: 'Korean',
        JA: 'Japanese',
        ES: 'Spanish',
        'PT-BR': 'Portuguese (Brazil)',
        FR: 'French',
        'PT-PT': 'Portuguese (Portugal)',
        EN: 'English',
        en_US: 'English',
        'en-US': 'English',
        en: 'English',
      },
    },
    specialties: {
      notFound: 'Not found specialties',
      adrenaline: 'Adrenaline',
      beach: 'Beach',
      mountain: 'Mountain',
      urban: 'Urban',
      cruise: 'Cruise',
      resort: 'Resort',
      gastronomy: 'Gastronomy',
      wine: 'Wine',
      adventure: 'Adventure',
      ecotourism: 'Ecotourism',
      shopping: 'Shopping',
      culture: 'Culture',
      sports: 'Sports',
      'Natureza & Aventura': 'Nature & Adventure',
      'Urbano - Lifestyle': 'Urban - Lifestyle',
      'Vinho, Azeite & Gastronomia': 'Wine, Olive Oil & Gastronomy',
      'Aventuras & Esportes': 'Adventures & Sports',
      'História, Arte & Cultura': 'History, Art & Culture',
      'Espiritualidade e Religião': 'Spirituality and Religion',
      'Turismo Acessível & Necessidades Especiais':
        'Accessible Tourism & Special Needs',
      'Viajar em Família ': 'Travel with Family',
      'Saúde & Bem Estar': 'Health & Well-Being',
      'Viagem Econômica - Super Dicas': 'Economic Travel - Super Tips',
      'Turismo LGBTQIA+': 'LGBTQIA+ Tourism',
      'Viagens de Luxo': 'Luxury Travel',
      Afroturismo: 'Afrotourism',
      'Comida e Gastronomia': 'Food and Gastronomy',
      'Arquitetura - Castelos': 'Architecture - Castles',
      'Arquitetura - Urbana': 'Architecture - Urban',
      'Arte e Cultura': 'Art and Culture',
      'Artes - Galeria ': 'Arts - Gallery',
      'Artes - Museu louvre': 'Arts - Louvre Museum',
      'Artes - Museu Vaticano': 'Arts - Vatican Museum',
      'Artes - Museus': 'Arts - Museums',
      'Eventos culturais - Festivais': 'Cultural events - Festivals',
      'História ': 'History',
      'História - Monumentos históricos': 'History - Historical monuments',
      'História - Campos de batalha': 'History - Battlefields',
      'Natureza - Astroturismo': 'Nature - Astrotourism',
      'Natureza - Atrações turísticas': 'Nature - Tourist attractions',
      'Natureza - Aurora Boreal': 'Nature - Northern Lights',
      'Natureza - Montanhismo': 'Nature - Mountaineering',
      'Natureza - Navegações fluviais': 'Nature - River navigation',
      'Rural - Vida Local': 'Rural - Local Life',
      'Aventuras e esportes - Bike': 'Adventures and sports - Bike',
      'Aventuras e esportes - Downhill': 'Adventures and sports - Downhill',
      'Aventuras e esportes - Hiking': 'Adventures and sports - Hiking',
      'Aventuras e esportes  Mergulho': 'Adventures and sports - Diving',
      'Aventuras e esportes - Mountain bike':
        'Adventures and sports - Mountain bike',
      'Aventuras e esportes - Selvas': 'Adventures and sports - Jungles',
      'Aventuras e esportes - Ski': 'Adventures and sports - Ski',
      'Aventuras e esportes - Trekking': 'Adventures and sports - Trekking',
      'Aventuras e esportes - Trilhas': 'Adventures and sports - Trails',
      'Esportes - Corridas e Maratonas': 'Sports -  Races and Marathons',
      'Comida - Comida de rua': 'Food - Street food',
      'Comida - Escola de gastronomia': 'Food - Gastronomy school',
      'Comida - Gastronomia': 'Food - Gastronomy',
      'Cruzeiros - Navegações Fluviais': 'Cruises - River Navigation',
      'Cruzeiros - Navegações marítimas ': 'Cruises - Maritime Navigation',
      'Enogastronomia ': 'Enogastronomy',
      'Vinho - Enoturismo - Vinícolas': 'Wine - Enotourism - Vineyards',
      'Vinho - Experiência Gastronômica': 'Wine - Gastronomic Experience',
      Espiritualidade: 'Spirituality',
      'Turismo religioso': 'Religious tourism',
      'Nômades digitais ': 'Digital nomads',
      'Saúde e bem estar - Spa no campo':
        'Health and well-being - Spa in the field',
      'Saúde e bem estar - Spa urbano': 'Health and well-being - Urban spa',
      'Saúde e bem estar - Termas': 'Health and well-being - Spas',
      'Urbano - Dicas de compras': 'Urban - Shopping tips',
      'Urbano - Vida local': 'Urban - Local Life',
      'Urbano - Vida quotidiana': 'Urban - Daily Life',
      'Viajar com crianças': 'Travel with children',
      'Viajar em família ': 'Travel with family',
      'Viagens de luxo ': 'Luxury Travel',
      'Turismo acessível ': 'Accessible Tourism',
      Astroturismo: 'Astrotourism',
      'Esportes - Golfe': 'Sports - Golf',
      'Natureza - Praias': 'Nature - Beaches',
      'Natureza - Trilhas e Caminhadas': 'Nature - Trails and Hikes',
    },

    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    errors: {
      errorSizeUpload: 'The image must be smaller than 2MB',
      errorTypeUpload: 'The image must be in jpg, jpeg or png format',
      errorUpload: 'Could not upload image.',
      birthDate: 'Invalid birth date.',
    },

    breadcrumb: {
      calls: 'Calls',
      history: 'History',
      home: 'Home',
      advisors: 'Advisors',
      scheduledTours: 'Scheduled tours',
      pendingBriefings: 'Pending Traveler Briefing',
      formBriefing: 'Traveler Briefing form',
      testimonials: 'Testimonials',
    },

    footer: {
      launchedRecently:
        'We have just launched our platform, and our development team is closely monitoring system instabilities or service disruptions acting swiftly to ensure your experience is the best it can be.',
      affiliates: 'Affiliates',
      privacy: 'Privacy',
      terms: 'Terms',
      legal: 'Legal',
      faq: 'FAQ',
      termsConditions: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
      aboutUs: 'About Us',
      subscribeSuccess: 'Thank you for subscribing!',
      newsletter: 'Subscribe to our Newsletter:',
      name: 'Name',
      email: 'E-mail',
      subscribe: 'Subscribe',
      subscribePlaceholder: 'Email',
    },
    weekdays: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    },
    sidebar: {
      aboutUs: 'About us',
      account: 'Account',
      faq: 'FAQ',
      findGuide: 'Find a guide',
      history: 'History',
      home: 'Home',
      messages: 'Messages',
      notifications: 'Notifications',
      profile: 'View profile',
      schedule: 'Scheduling',
      settings: 'Settings',
      admin: {
        home: 'Admin home',
        prices: 'Prices',
      },
    },

    terms: {
      title: 'I declare that I have read and accept the ',
      terms: 'Terms and Conditions',
    },

    mentorList: {
      title: 'Travel Expert List',
      filterByStatus: 'Filter by status',
      searchPlaceholder: 'Search by name',
      view: 'Show approved',
      download: 'Export CSV',
      suspend: 'Suspend selected',
      showing: 'Showing',
      mentors: 'mentors',

      table: {
        name: 'Name',
        country: 'Country',
        subscriptionDate: 'Subscription date',
        status: 'Status',
      },
    },

    agenda: {
      available: 'Available Times',
      online: 'Video Chat',
      inPerson: 'In-Person - Guided Tour',
      noEvents: 'No events scheduled in the next 3 days.',
      title: 'Book',
      nextEvents: 'Bookings',

      travellerCancelModal: {
        title: 'Cancel appointment',
        paragraph1: 'Are you sure you want to cancel this appointment?',
        paragraph2:
          'By canceling the appointment, you will lose the information filled in the Traveler Briefing.',
        paragraph3:
          'Are you sure you want to cancel this appointment? Less than 48 hours remain until the meeting.',
        paragraph4:
          'In this case, as informed at the time of hiring the service, a fine of 100% of the original value is applied, that is, no amount will be refunded.',
        paragraph5: 'Do you want to cancel the appointment?',
        paragraph6:
          'Are you sure you want to cancel this appointment? Less than 72 hours remain until the in-person tour.',
        paragraph7:
          'In this case, as informed at the time of hiring the service, a fine of 100% of the original value is applied, that is, no amount will be refunded.',
        paragraph8: 'Do you want to cancel the appointment?',
      },

      rescheduleModal: {
        title: 'Reschedule appointment',
        paragraph1: 'Are you sure you want to reschedule this appointment?',

        paragraph2: 'Less than 48 hours remain until the meeting.',
        paragraph3:
          'If you wish to reschedule, you will pay a fine of 100% of the original value, which would be ${EXTRA_COST_VALUE}, as informed at the time of hiring the service.',
        button: 'Pay the fine',
        rescheduleButton: 'Confirm rescheduling',

        advisorText: `<p>Rescheduling cannot be done by the Travel Expert at this time.</p>

        <p> Once confirmed, the system will <strong>request the traveler</strong> to select a new day and time from your agenda.</p>

        <p>Please check the available time slots to avoid requesting rescheduling again.</p>

        <p>Please, check the available time slots and block your agenda according to your availability so that rescheduling is not necessary again.</p>

        <p> If we offer other times for the traveller, it may be that the initially scheduled time is the only time he had available. In this case, we will also allow the customer to choose to cancel the meeting for free and look for another Travel Expert.</p>

        <p>Do you want to proceed with the rescheduling request?</p>

        <p>Remember: A high rate of rescheduling <strong>negatively impacts your evaluation as a Travel Expert</strong>, and consequently your position in the platform's search ranking.</p>
        `,
        cancelSolicitation: 'Cancel request',
        agree: 'Yes, request rescheduling',
        error: 'Error rescheduling appointment',
        success: 'Meeting reschedule requesting has been sent successfully.',
      },

      travellerAskedReschedule: {
        title: 'Rescheduling Requested by the Travel Expert',
        text: `<p>There has been an unexpected event, and your Travel Expert is notifying in advance that they won't be able to attend the meeting on the originally scheduled date.</p>
        <div class="reschedule__hour">
          <p>Original date and time:</p>
          <span><strong>{DATA}</strong></span>
        </div>
        <p>In this case, you can schedule a new date or time with the same Travel Expert or request a free cancellation of this meeting to find another guide.</p>`,
        cancelButton: 'Cancel Meeting',
        confirmButton: 'Reschedule',
        seeMyOptions: 'Go to my calendar',
      },

      cancelModal: {
        title: 'Cancel appointment',
        paragraph1: 'Are you sure you want to cancel this appointment?',
        paragraph2:
          'The high cancellation rate <strong>negatively impacts your rating as a Travel Expert on Contenta360</strong>, and consequently your recommendation position in the platform search ranking.',
        paragraph3:
          'We recommend that you organize your schedule, blocking the times you will be available so you don’t have to cancel if a client chooses an inconvenient time.',
        button: 'Cancel appointment',
        success: 'Appointment canceled successfully!',
        error: 'Error canceling appointment',
      },
      scheduleModal: {
        canceledByTraveler: 'Canceled by traveler',
        canceledByAdvisor: 'Canceled by advisor',
        canceledByAdministrator: 'Canceled by administrator',
        canceled: 'Canceled',
      },
    },

    withoutYCBM: {
      title: 'Sign up with our partner to manage your schedule.',
      content: {
        title: 'Meet our partner',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer placerat viverra risus ac finibus. Donec nec metus massa. Vestibulum faucibus tellus arcu, sit amet imperdiet velit ultricies sit amet. Nam mattis enim non purus laoreet, eu finibus lacus tincidunt. Integer vel risus rhoncus, faucibus arcu id, luctus nulla.',
        boldDescription:
          'Sign up using the same email you used to sign up with Contenta360',
      },
      headerMessage: {
        button: 'Edit schedule',
        title: 'Configure your schedule with our partner.',
      },
    },

    buttons: {
      accept: 'Accept',
      advisor: 'Advisor',
      approve: 'Approve',
      beAdvisor: 'Register as a Travel Expert',
      bookNow: 'Book now',
      briefing: 'Traveler Briefing',
      cancel: 'Cancel',
      enter: 'Login',
      enterCall: 'Join the meeting',
      evaluate: 'Rate',
      the: 'the',
      finishBeAdvisor: 'Finish Travel Expert register',
      finishBeTraveler: 'Finish traveler register',
      loginAdvisor: 'Login as a Travel Expert',
      loginTraveler: 'Login as a traveler',
      signUpAsTravelExpert: 'Sign up to become a travel expert',
      makeLogin: 'Login',
      outOfRange:
        'You only have access to the meeting 1 hour before and not possible to enter after the end of the meeting',
      reprove: 'Disapprove',
      resume: 'Resume',
      schedule: 'Schedule',
      scheduleNow: 'Book now',
      seeBriefing: 'See Traveler Briefing',
      seeMore: 'See more',
      seeResume: 'Fill out meeting summary',
      suspend: 'Suspend',
      willBeEnabled:
        'You will be able to enter 1 hour before the start of the meeting',
    },

    statusCode: {
      approved: 'Approved',
      pending: 'Pending',
      disapproved: 'Disapproved',
      suspended: 'Suspended',
      paid: 'Paid',
      launched: 'Launched',
    },

    unloggedModal: {
      text: `Hi! <br /><br /> Before moving forward with your booking, you'll need to log in or create your account on Contenta360. <br /><br /> Welcome to a new way of planning trips!`,
    },

    notTravellerModal: {
      text: `Hi! <br /><br /> We noticed that you are not logged in as a traveler. <br /><br /> To continue with your booking, please log in or create your account on Contenta360. <br /><br /> Welcome to a new way of planning trips!`,
    },

    mentorDetails: {
      headers: {
        title: 'Travel Expert details',
        contactInfo: 'Contact information',
        guideInfo: 'Professional registration',
        companyInfo: 'Represents company in the tourism sector',
        destinyInfo: 'About the destination where he/she works',
        specialtiesInfo: 'Specialties',
      },
      contactFields: {
        status: 'Status',
        birthday: 'Birthday',
        countryBirth: 'Country of birth',
        creationAccountDate: 'Account creation date',
        mobilePhone: 'Mobile phone',
        email: 'Email',
        countryResidence: 'Country of residence',
        stateResidence: 'State',
        cityResidence: 'City',
        address: 'Address',
        zipCode: 'Zip code',
        guideStory: 'Guide story',
      },

      guideFields: {
        emitter: 'Issuing agency',
        registration: 'Registration number',
        associatedSince: 'Associated since',
        valueHour: 'Hourly rate',
        currency: 'Currency',
        city: 'City',
      },

      companyFields: {
        name: 'Company name',
        cnpj: 'CNPJ',
        role: 'Position',
      },

      destinyFields: {
        country: 'Country',
        state: 'State',
        city: 'City',
        regionDetails: 'Region details',
        languages: 'Languages',
      },

      specialtiesFields: {
        options: 'Options',
        others: 'Others',
      },
    },

    tables: {
      tooltipTitle: 'Click to sort',
    },

    filters: {
      title: 'Active filters',
      name: 'Name',
      status: 'Status',
    },

    mentorProfile: {
      expertPage: 'Check details',
      pricing: 'Pricing',
      meetingType: {
        online: 'Online',
        presential: 'In-person tour',
      },
      time: {
        package1h: 'Valor por hora',
        package4h: 'Price per 04 hours package',
        package6h: 'Price per 06 hours package',
        package8h: 'Price per 08 hours package',
        package10h: 'Price per 10 hours package',
      },
      bookTime: 'Schedule a time',
      bookTimeTitle: 'Schedule a time',
      readMore: 'Read more',
      aboutMyArea: 'About my area',
      priceHour: 'Price per hour / Online meeting',
      generalInfo: 'General information',
      contact: 'Contact',
      profile: 'Profile',
      personalData: 'Personal Information',
      professional: 'Professional',
      membership: 'Membership',
      video: 'Video',
      license: 'License',
      addedVideo: 'Video added previously (to replace, just send a new one)',
      addedLicence:
        'License added previously (to replace, just send a new one)',
      videoMessage:
        'The video must be in <b>.mp4</b> format and a maximum weight of <b>200MB</b>.',
      destiny: 'Destination',
      idioms: 'Languages',
      specialties: 'Specialties',
      financial: 'Financial',
      residence: 'Residence',
      profilePhrase: 'Profile phrase',
      addNewCertificate: 'Add new certification',
      guideLicense: 'Upload your valid tour guide licence.',
      guideLicenseLabelAdmin:
        'Tour Guide License - License or Professional Certificate',
      uploadLicense: 'Upload your Guide License',
      whyAmI: 'Why I love being a Travel Expert',
      whyAmIQuestion: 'Why am I a Travel Expert?',
      noPhrase: 'This advisor does not have a profile phrase yet',
      photoMessage:
        'The image must be in <b>.jpeg</b> or <b>.png</b> format and a maximum weight of <b>2MB</b>.',
      uploadingPhoto: 'Uploading photo...',
      experience: 'Expert in',
      rating: 'Rating',
      investmentValue: 'Price per hour',
      guideLocation: {
        title: 'Location where you work',
      },
      guideIdioms: {
        title: 'Fluent Languages',
        otherLanguages: 'Others',
      },
      guideSpecialties: {
        change: 'Want to update your specialties?',
        tellABitLabel:
          'In one of the languages you master, tell us a little about yourself. Ex: your time of formation and professional experience, your specialties in the region where you work, number of customers attended.',
      },
      financialData: {
        title: 'Coin',
        bankTitle: 'Financial Data',
        bankSubtitle:
          'Where you will receive payment for your international services.',
        bankBrazilAccount:
          'Do you have a bank account in Brazil? Please provide the information below.',
        onlineConsultation: 'Online Travel Planning Video Chat Rates',
        valueHourRequired: 'Price/hour is required',
        presencialTour: 'In-person guided tour',
        presentialConsultation:
          'In-person Guided Tour at Location Rates (up to 6 people)',
        valueHour: 'Price/Hour',
        valueHour2person: 'Rate for up to 2 people',
        valueHour3person: 'Rate for up to 3 people',
        valueHour4person: 'Rate for up to 4 people',
        valueHour6person: 'Rate for up to 6 people',
        valueFourHours: 'Price for 4 hours',
        valueSixHours: 'Price for 6 hours',
        valueEightHours: 'Price for 8 hours',
        valueTenHours: 'Price for 10 hours',
        bankAccount: {
          bankNumber: 'Bank number',
          bankName: 'Bank name',
          agencyNumber: 'Agency number',
          accountNumber: 'Account number',
          swiftCode: 'SWIFT code',
          ibanCode: 'IBAN code',
          bank: 'Bank',
          agency: 'Agency',
          account: 'Account',
          digit: 'Digit',
          bankRequired: 'Bank is required',
          agencyRequired: 'Agency is required',
          accountRequired: 'Account is required',
          digitRequired: 'Digit is required',
        },
        stripeTitle: 'Stripe account number',
        stripeSubtitle: 'Do you have an account with our partner Stripe?',
        stripeAccount: 'Stripe account',
        stripeInfoList: {
          text1:
            'The generated link is for single use. If you have already used the link, reload the page to generate another link.',
          text2:
            'Stripe is where you set up the account to receive money for your services. Through it, you can configure the bank account you want to receive the money, as well as other information.',
        },
        rule1: 'Paying income taxes is your individual responsability.',
        rule2:
          'The price of the entire tour must be disclosed including all the price of the tour guide (Example: round-trip transportation + food).',
        rule3: 'There will be no additional payment from the client.',
        rule4:
          'Complex in-person onsite tours, with additional costs beyond the time of the tour guide, cannot be sold through Contenta360˚ at this time, in the first phase of the platform.',
      },
      schedule: {
        title: 'Schedule your tourism mentoring now',
        videoCall: 'Video call',
        presential: 'In-person tour',
      },
      licensedGuide: 'Licensed guide',
      valueHour: {
        presentialTour: 'In-person tour',
        onlineConsultation: 'Online consultation - virtual meeting',
        hour: 'hour',
        untilFourHour: 'up to 4 hours',
      },
      videoData: {
        uploadText: 'Drag and drop a video here or ',
        uploadLink: 'upload',
        rules: {
          time: 'Max video length up to 90 seconds.',
        },
        success: 'Video uploaded successfully',
        error: 'Error uploading video',
        sizeError: 'Video size must be maximum 200MB',
      },

      membershipData: {
        title: 'Your Subscription',
        newUserMessage:
          'Welcome to Contenta360! By activating the subscription, your credit card will be requested, but you will not be charged until the end of the trial period. We hope you enjoy the platform!',
        cards: 'Registered cards',
        currencyReceived: 'Currency for receiving payments',
        accountStatus: 'Account status',
        acountStart: 'Started on',
        usedCard: 'Card being used',
        nextExpiration: 'Next renewal',
        cancelMembership: 'Cancel Subscription',
        changeCard: 'Change card',
        actual: 'Actual',
        deactivateMembership: 'Deactivate Subscription',
        reactivateMembership: 'Reactivate Subscription',
        activateMembership: 'Activate Subscription',

        cancelMembershipReason: 'Please describe the reason for cancellation',
        selectOption: 'Select an option',
        options: {
          noMoney:
            'I am not able to pay the monthly fee of the platform at the moment.',
          noClients: 'I have not been able to get clients.',
          noLike: 'I did not like the platform.',
          impediment: 'I am prevented for health reasons.',
          goActOtherSegment: 'I will work in another segment.',
        },
        other: 'If you wish, make observations below',
        inactive: 'Inactive',
        canceled: 'Canceled',
        active: 'Active',
        trial: 'Trial',
        newUser: 'New user',
        table: {
          data: 'Date',
          card: 'Card',
          value: 'Value',
          status: 'Status',
          actions: 'Actions',
        },
      },
    },

    resetPassword: {
      success: {
        title: 'E-mail sent',
        description:
          'We have sent an email with instructions to reset your password.',
      },
      error: {
        title: 'Error',
        description: 'Error sending email.',
      },
    },

    briefing: {
      hour: 'Time',
      title: 'Traveler Briefing',
      scheduled: 'Scheduled for',
      localTime: 'Local time of the traveler',
      chargedValue: 'The charged amount',
      scheduledFor: 'Scheduled for',
      travellerInterests: {
        title: 'General interests of the traveler',
      },
      remainingTime: 'Remaining time to make the payment:',
      form: {
        success: 'Traveler Briefing sent successfully',
      },
      actions: {
        goHome: 'Go home',
      },
    },

    mentorHome: {
      title: 'You with authority, international projection and profitability!',
      fullAgenda: 'Complete agenda',
      seeCompleteAgenda: 'See my complete agenda',
      noScheduleTitle: 'Your calendar has no upcoming meetings',
      noScheduleParagraph:
        'Contenta360 presents your profile in an intelligent way for travelers interested in your locality and specialties.',
      noScheduleParagraph2:
        'Keep your profile updated and exercise the collaboration by sending your videos to boost the search for your city and region.',
      nextScheduleTitle: 'My scheduled meetings',
      acceptPenalty: 'Accept penalty',
      giveUpCancel: 'Give up cancellation',
      cancelRequest: 'Cancellation request',
      subscribeNow: 'Subscribe now',
      notMember: {
        title: 'Ops! Your profile is still under review',
        paragraph1:
          'Thank you for pre-registering as a travel expert with Contenta360.',
        paragraph2:
          'To complete the process, please schedule an interview with our curation team using the link below.',
        thisLink: 'schedule here.',
        listLinks: {
          portuguese: 'Schedule in portuguese',
          english: 'Schedule in english',
          schedule: 'Schedule',
        },
        paragraph3:
          'If you can’t find a suitable time, email us at partners@contenta360.com, and we’ll arrange a convenient appointment.',
        paragraph4: 'We look forward to meeting you!',
        doubts: 'If you have any questions, contact us via email ',
      },
      pendingItems: {
        title: 'You have pending items in your profile',
        guideLicenseURL: 'Tour Guide License',
        imageURL: 'Profile photo',
        status: 'Status',
        stripeAccountEnabled: 'Stripe Account',
        videoURL: 'Presentation video',
        ycbmAccountConfirmed: 'YCBM Account Confirmed',
        guideLicenseApproved: 'Tour Guide License approval',
      },
      pendingPayments: 'Pending payments',
    },

    modal: {
      attention: 'Attention',
      clickRead: 'Click here and read the regulation',
      cancelSchedule: {
        paragraph1:
          'Canceling or rescheduling a video call with less than 24 hours in advance may generate',
        penaltyAmount: 'penalty of 20%',
        paragraph2: 'on the total amount paid by the client.',
      },
      evaluate: {
        title: 'Evaluate',
        subtitle: 'Your evaluation is very important to us!',
        traveler: 'Traveler',
        advisor: 'Advisor',
        successMessage: 'Evaluation successfully completed!',
        rateQuestions: {
          interaction: 'Interaction and connection with you',
          content: 'Quality of Travel Expert content',
          qualityAudio: 'Audio and video quality',
          punctuality: 'Punctuality',
          behavior: 'Behavior in the session',
          otherComments: 'If you wish, add comments to your evaluation.',
        },
      },
    },

    register: {
      authGoogleSuccess: 'Login with Google successfully!',
      authGoogleSuccessMessage:
        'Please complete the registration by clicking now to save.',
      CPF: 'CPF',
      CPFRequired: 'CPF is required',
      gender: 'Gender',
      genderRequired: 'Gênero is required',
      masculine: 'Male',
      feminine: 'Female',
      nonBinary: 'Non-binary',
      preferNotToSay: 'Prefer not to say',
      yes: 'Yes',
      no: 'No',
      civilStatus: 'Civil status',
      civilStatusRequired: 'Civil status is required',
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
      widower: 'Widower',
      separated: 'Separated',
      partner: 'Partner',
      completeRegister: 'Registration',
      successRegistered: 'Confirm your email',
      successRegisteredMessage: 'Please confirm your email address',
      successRegisteredMessage2:
        'To complete your registration, please check your inbox and click on the confirmation link we sent you.',
      successRegisteredMessage3:
        'If you don’t see the email, please check your spam or junk folder.',
      others: 'Others',
      success: 'Success registered!',
      finishProfile: 'Finish your profile!',
    },

    sideProfile: {
      changePassword: 'Change password',
      updatePhoto: 'Update photo',
      accountStatus: 'Account status',
      professionalProfile: 'Professional profile in social networks',
      mobilePhone: 'Cell phone',
      accountStatusLabel: {
        notPaid: 'Not paid',
        waitingPayment: 'Waiting payment',
        pendingPayment: 'Pending payment',
        notApproved: 'Not approved',
        approved: 'Approved',
        blocked: 'Blocked',
        pending: 'In analysis',
      },
    },

    findAdvisor: {
      scheduleModal: {
        title: 'Book now',
        amount: 'Select tour duration:',
        valueEightHours: '8 hours',
        valueFourHours: '4 hours',
        valueSixHours: '6 hours',
        valueTenHours: '10 hours',
        noPresential: 'This Advisor does not offer in-person tours',
        participants: 'Participants (up to 6 people allowed):',
        notEnoughSlots: 'Not enough slots available',
      },
    },

    cookies: {
      message: 'Cookies Policy',
      description:
        'This site uses cookies to improve your experience. By continuing to browse, you agree to the use of cookies.',
    },

    history: {
      searchBar: {
        specificDestiny: 'Search specific appointments',
        specificDestinyPlaceholder:
          'Use the name of the advisor or the location of the trip',
        specificDestinyAdvisor:
          'Use the name of the advisor or the location of the trip',
        specificDestinyTraveller: 'Use the name of the traveler',
        showAppointments: 'Show appointments',
        past: 'Previous',
        future: 'Future',
        virtual: 'Travel planning',
        presential: 'In person tour',
      },
    },

    traveler: {
      home: {
        randomTexts: {
          title1: 'Travel Informed!',
          text1: 'Connect with local tour guides across 5+ continents',
          title2: 'Make Every trip Epic.',
          text2: 'Travel planning made easy & fun!',
          title3: 'Travel Smarter!',
          text3: 'Get expert advice from our local tour guides.',
          title4:
            'Book a local tour guide for a personalized travel planning experience.',
          text4: 'Get tailored advice, enjoy unique travel experiences.',
          title5: 'Enjoy more of the journey!',
          text5: 'Plan your trips effortlessly with Contenta360.',
          title6: 'Book an in-person tour after your online meeting.',
          text6: 'Be guided by the same tour guide you connected with online',
        },
        title:
          'Plan your trip, <br /> easy and fast, with custom<br/> tips, just for you.',
        paragraph1:
          'Our experts, who live in the tourist destination you are going to visit, are the people who live firsthand everything you need to know. They have authority to help you in this phase of your trip.',
        paragraph2:
          'Find here the Mentor or Mentress who have the face of your next adventure, welcome to our Forum! Our living room is virtual, but full of human warmth.',
        myTravelDestiny: 'Location',
        virtual: '1:1 Online Travel Planning',
        inPerson: 'In-person Custom Private Tour',
        preferredDestinyPlaceholder: 'Explore like a local - where to?',
        preferredMentor: 'Travel Expert',
        knowTravelAdvisor: 'I know who I want to talk to',
        preferredMentorPlaceholder: 'Search by name',
        meetingMentor: 'Plan online or explore in person?!',
        notTalkedMentor: 'Have you not talked to our Mentors yet?',
        meetMentors: 'Meet some of our Travel Experts',
        findSpecialty: 'Search by Travel Expert specialty',
        findSpecialtyPlaceholder: 'Find by specialty of the Travel Expert',
        specialtiesModalTitle: 'Specialties',
        specialtiesModalButton: 'Find specialties',
        internationalPurchase: 'International purchase',
        priceSubjectChange: 'Price subject to exchange rate and taxes.',
        virtualLabel: 'Video Chat - Online Travel Planning',
        inPersonLabel: 'In-person guided tour at location',
      },

      scheduleBriefing: {
        minimumCharge:
          'A $value administrative fee will be charged on the credit card',
        giftCardError: 'Invalid gift card',
        giftCardSuccess: 'Gift card successfully applied',
        subtotal: 'Subtotal',
        discount: 'Discount (Gift Card)',
        totalPayment: 'Total to pay',
        hasSomegiftCard: 'Do you have any Gift Card?',
        giftCard: 'Discount voucher (Gift Card)',
        invalidCardName: 'Invalid card name',
        saveCreditCard: 'Save credit card',
        dateRequired: 'Date is required',
        title: 'Scheduling Traveler Briefing',
        paymentTitle: 'Payment',
        investmentValue: 'Price',
        requiredField: 'Required field',
        paymentDetailsSubtitle: 'Purchase details',
        paymentDetailsSubtitleGiftCardWithoutTravel:
          '*The Travel Expert will be chosen by ${GIFTED_NAME}, on the day and time',
        paymentAddress: 'Billing address',
        paymentYourAddress: 'Your address',
        paymentDetails: 'Payment details',
        membershipPayment: 'Membership payment',
        editMembership: 'Edit membership',
        paragraph1:
          'To provide better use of your video call time with the Advisor, we have prepared a quick questionnaire about your profile, please respond as clearly as possible.',
        paragraph2: 'These information will be shared with your Advisor.',
        youWillTravel: 'Are you going to travel?',
        withFamily: 'With the family',
        withFriends: 'Group of friends',
        withPartner: 'Couple',
        withAlone: 'Individual',
        expectedDateTitle: 'Do you already have a probable date to travel?',
        hasKids: 'With children under 15 years old?',
        ageRange: 'Age range',
        ageRangePlaceholder: 'Select the age range',
        ageRangeRequired: 'Age range is required',
        ageRange1: '0 to 2 years',
        ageRange2: '3 to 5 years',
        ageRange3: '6 to 10 years',
        ageRange4: '11 to 15 years',
        ageKids: 'If it is a child, what is the age group?',
        yourAddress: 'Your billing address',
        describeHere: 'Describe here',
        specialDate: {
          title: 'Will you be celebrating a special date?',
          birthday: 'Birthday',
          anniversary: 'Marriage anniversary',
          honeymoon: 'Honeymoon',
          vacation: 'Vacation',
          kidsVacation: 'Kids vacation',
          remoteWork: 'Remote work',
          digitalNomad: 'Digital nomad',
          others: 'Others',
        },
        specialNeeds: {
          title: 'Do you or someone who travels with you have special needs?',
          tellUs: 'Tell us a little about these needs',
        },
        theme: {
          title:
            'Tell your specialist a little more about the themes you want to address in the video chat meeting.',
          addTheme: 'Add theme',
        },
        probableDate: {
          probableDate: 'Do you already have a probable date to travel?',
          probableDateText:
            'These information guide your Travel Expert and help in the planning.',
          departureDate: 'Departure date',
          from: 'From',
          origin: 'Origin',
          to: 'To',
          destiny: 'Destiny',
          airplane: 'Airplane',
          transportation: 'Means of transportation',
          duration: 'Trip duration',
          add: 'Add more destinations',
          day: 'Day',
          days: 'Days',
        },

        expectations: {
          title: 'What are the main expectations for your next trip?',
          label:
            'Ex: Live the city like a local, stay in the countryside, practice sports, adventures, gastronomy, wine tasting, culture, spirituality',
          placeholder: 'Describe here',
        },
        confirmedPayment: {
          title: 'Payment confirmed',
          paymentProcessed: 'Your payment has been processed.',
          paragraph1:
            'Your payment has been processed. How about filling out the Traveler Briefing for your Travel Expert to better assist you?',
          goAhead: 'Go ahead',
          rescheduled:
            'Your payment has been processed, and the meeting has been rescheduled in the system.',
          button: 'Back to home page',
          briefing: 'Fill out Traveler Briefing',
        },
        editedMembership: {
          title: 'Membership edited',
          paragraph1: 'Your membership has been edited',
        },
        failedPayment: {
          title: 'We identified a failure in your payment',
          subtitle: 'Your payment was not processed, try again.',
          paragraph1:
            'Do not worry, if you need to leave the site at the moment you can make the payment later.',
          payOtherTime: 'Pay later',
        },
        noNeighborhood: 'Neighborhood not informed',
        travelMotivation: {
          title: 'Travel reasons',
          budget: 'What is your travel budget?',
          backpacker: 'Backpacker',
          economic: 'Economic',
          intermediate: 'Intermediate',
          luxury: 'Luxury',
          intermediary: 'Intermediary',
          lux: 'Luxury',
          higher: 'Superior',
          other: 'Prefer not to say',
          preferNotToSay: 'Prefer not to say',
          bugdet: 'What is your travel budget?',
          leisure: 'Leisure',
          business: 'Business',
          leisureAndBusiness: 'Leisure and business',
          hostel: 'Hostel',
          inexpensive: 'Inexpensive',
          moderate: 'Moderate',
          expensive: 'Expensive',
          veryExpensive: 'Very expensive',
          superior: 'Superior',
          luxuryHotel: 'Luxury hotel',
          superLuxuryHotel: 'Super luxury hotel',
          restaurantRecommendation: 'Restaurant recommendation',
          intermediateRestaurant: 'Intermediate restaurant',
          refinedRestaurant: 'Refined restaurant',
          hauteCuisineRestaurant: 'Haute cuisine restaurant',
          hotelRecommendation: 'Hotel recommendation',
          birthday: 'Birthday',
          anniversary: 'Marriage anniversary',
          honeymoon: 'Honeymoon',
          vacation: 'Vacation',
          kidsVacation: 'Kids vacation',
          remoteWork: 'Remote work',
          digitalNomad: 'Digital nomad',
          requiredField: 'Required field',
        },

        titlePresential: 'In-person Meeting - Guided Tour',
        titleOnline: 'Video Chat',
        codeOfConduct: 'I have read and agree to the',
        linkCode: 'code of conduct',
        ofPlatform: 'of the platform',
        contactEmail: 'Questions: contactus@contenta360.com',
        advisor: 'Travel Expert:',
        cancelReschedule: 'For cancellations and rescheduling',
        whereMeeting: 'Where will the meeting be?',
        cancelNoPenalty:
          'No penalty if canceled or rescheduled more than 48 hours in advance.',
        cancelPenalty:
          '100% penalty of the paid amount if canceled or rescheduled less than 48 hours in advance.',

        onlineMeeting: {
          whereMeeting: 'Where will the meeting be?',
          reservedTime: 'Reserved Time',
          meetingInfo1:
            'Your meeting is scheduled for 11:00 AM on September 16, 2023. Duration: 01 hour',
          meetingLocation:
            'The web conference link is sent after payment and reservation confirmation.',
          generalRules: 'General Rules',
          briefing:
            'After making the payment, please fill out the Traveler Briefing with information to help your Travel Expert assist you better.',
          allowedParticipants:
            'We allow up to 4 people in the video conference room.',
        },

        presentialMeeting: {
          reservedTime: 'Reserved Time',
          meetingInfo1:
            'In-person meeting scheduled for 11:00 AM on September 16, 2023. Package of 03 hours',
          meetingInfo2:
            'The chat to communicate with your Travel Expert will be open 48 hours before the start of your tour.',
          meetingLocation:
            'The web conference link is sent after payment and reservation confirmation.',
          generalRules: 'General Rules',
          afterPayment:
            'After making the payment, please fill out the Traveler Briefing.',
        },
        alreadyParticipate: {
          title:
            'Have you already participated in an Video Chat with the Travel Expert ${ADVISOR_NAME}?',
          text: 'We recommend having a prior Video Chat before the in-person meeting so that both the Traveler and the Travel Expert can get to know each other.',
          required: 'Required field',
        },
        meetingPoint: {
          title: 'Meeting Point',
          hotelName: 'Name of the hotel where you are staying',
          hotelRequired: 'Hotel is required',
          address: 'Address',
          addressRequired: 'Address is required',
        },
        wishTour: {
          title: 'How do you wish your tour to be?',
          label:
            'Based on what was discussed in your planning meeting, describe to your Travel Expert how you would like your tour to be.',
          placeholder: 'Describe here',
        },
        placesVisit: {
          title: 'Which attractions would you like to visit?',
          placeholder: 'Describe here',
        },
        requiredPoint: {
          title: 'Is there any mandatory stopping point?',
          label: 'Specify the mandatory point',
          addrequiredPoint: 'Add point',
        },
        childrenOrOldPeople: {
          title: 'Are there children or elderly people in your group?',
          required: 'Required field',
        },
        restrictionFood: {
          title: 'Does anyone in your group have dietary restrictions?',
          label: 'Specify the restrictions',
          placeholder: 'Describe here',
        },
        rulesModal: {
          hours: 'hours',
          hour: 'hour',
          online: {
            title: 'Video Chat',
            advisor: 'Travel Expert',
            meetingDuration: 'Reserved Time',
            scheduleDate: 'Your meeting is scheduled for',
            scheduleDateComplement: 'on',
            duration: 'Duration',
            chatDescription: '',
            meetingLocationInfo: 'Where will the meeting be?',
            locationDetails:
              'The web conference link is sent after payment and reservation confirmation.',
            locationDetails2: '',
            generalRules: 'General Rules',
            rule1:
              'After making the payment, please fill out the Traveler Briefing with information to help your Travel Expert assist you better.',
            rule2: 'We allow up to 4 people in the video conference room.',
            rescheduleTitle: 'For cancellations and rescheduling',
            rescheduleRule1:
              'No penalty if canceled or rescheduled more than 48 hours in advance.',
            rescheduleRule2:
              '100% penalty of the paid amount if canceled or rescheduled less than 48 hours in advance.',
            confirmationButton: 'Accept',
            terms1: 'I have read and agree to the',
            terms2: 'code of conduct',
            terms3: 'of the platform.',
            contact: 'Questions: contactus@contenta360.com',
          },
          presential: {
            title: 'Presential Meeting',
            advisor: 'Travel Expert',
            meetingDuration: 'Meeting duration',
            scheduleDate: 'Meeting scheduled for',
            scheduleDateComplement: 'of the day',
            duration: 'Package of',
            chatDescription:
              'The Chat to talk with your Travel Expert will be open 48 hours before the start of your tour.',
            meetingLocationInfo: 'Where the meeting will be',
            locationDetails:
              'The meeting point must be informed and/or confirmed 48 hours before your tour.',
            locationDetails2:
              'Provide in the Traveler Information the number of people who will take the tour with you. Maximum allowed: 06 people.',
            generalRules: 'General rules',
            rule1:
              'After making the payment, fill in the Traveler Information for your trip.',
            rule2: '',
            rescheduleTitle: 'For cancellations and rescheduling',
            rescheduleRule1:
              'No penalty if canceled or rescheduled more than 72 hours in advance.',
            rescheduleRule2:
              'Penalty of 100% of the paid amount if canceled or rescheduled less than 72 hours in advance.',
            confirmationButton: 'Accept',
            terms1: 'I have read and agree to the',
            terms2: 'code of conduct',
            terms3: 'of the platform.',
            contact: 'Questions: contactus@contenta360.com',
          },
        },
        processingPayment: {
          title: 'Processing payment',
          text: 'Please wait while we process your payment',
        },
      },

      historyCalls: {
        title: 'Call history',
        futureCalls: 'Future appointments',
        pastCalls: 'Past appointments',
        noFutureCalls: 'You have no future appointments',
        noPastCalls: 'You have no past appointments',
        foundResults: 'Found results',
        noResults: 'No results found',
        clearFilters: 'Clear filters',
      },

      resume: {
        title: 'Summary',
        yourGuideNotes: 'Your guide notes',
        noNotes: 'No notes',
        downloadChat: 'Click to download the chat history for this meeting',
        yourInterests: 'Your interests',
        yourSpecialties: 'Your specialities',
        attendmentArea: 'Attendance area',
        specialities: 'Specialties',
        experience: 'Experience',
        hotels: 'Hotels',
        description: 'Description',
        scheduledFor: 'Scheduled for',
        localTimeTraveller: 'Local time of the traveler',
        value: 'Value',
        guideNotes: 'Your guide notes',
        packages: {
          title: 'Liked the meeting? Hire more hours',
          onlineMeeting: 'Video Chat',
          presentialMeeting: 'Presential Meeting',
          package1h: 'Price per hour: ',
          package4h: 'Package of 4h: ',
          package6h: 'Package of 6h: ',
          package8h: 'Package of 8h: ',
          package10h: 'Package of 10h: ',
        },
        noBriefing: 'The traveler did not fill out the Traveler Briefing',
        interestedIn: 'Traveler interests',
        travelReason: 'Travel reason',
        budget: 'Travel budget',
        hotelRecommendation: 'Hotel recommendation',
        restaurantRecommendation: 'Restaurant recommendation',
        topicsAddressMeeting: 'Topics',
        expectation: 'Main expectations for the trip',
        probableDateTravel: 'Probable date to travel',
        withWhoGoTravel: 'Number and relationship of travelers',
        specialDate: 'Special date',
        hasSpecialNeed: 'Anyone with special needs?',
      },
    },

    filtersTag: {
      beach: 'Beach',
      mountain: 'Mountain',
      urban: 'Urban',
      cruise: 'Cruise',
      resort: 'Resort',
      gastronomy: 'Gastronomy',
      wine: 'Wine',
      adventure: 'Adventure',
      ecotourism: 'Ecotourism',
      shopping: 'Shopping',
      culture: 'Culture',

      adrenaline: 'Adrenaline',
      sports: 'Sports',
    },

    toast: {
      pictureSaved: 'Picture saved successfully',
      emailSent: 'E-mail for password change sent successfully',
      errorEmailSent: 'Error sending e-mail for password change',
    },

    meeting: {
      reloadModal:
        'Ops! Something went wrong. Please reload the page and try again!',
      advisorNotShow:
        'Dear {travelerName}, <br /><br />' +
        'It seems that your travel expert encountered an issue that prevented him/her from joining the meeting. <br /><br />' +
        'We sincerely apologize for this and will reach out to you soon with further details. <br />' +
        'The meeting will be closed, and we will process a full refund for the amount paid for this video chat meeting. <br /><br /><br />' +
        'Sincerely, <br />' +
        '<b>Contenta360 Operations Team</b>',

      buttons: {
        copy: 'Link',
        waitingLocation: 'Waiting meeting point',
        seeMeetingPoint: 'See meeting point',
        selectCamera: 'Select camera',
      },

      modalTimeNearToEnd: {
        title: 'Meeting time is almost over',
        description:
          'The meeting time is almost over. Use this time to finalize the conversation and schedule a new meeting if necessary.',
      },

      recordNotes: {
        copy: 'Copy call link to share with your travel group',
        message:
          "This meeting is being recorded and the knowledge shared in this meeting is the presenter's intellectual property. Recording and capturing screenshots is strictly prohibited without the prior written permission of the presenter or Contenta360. Violation of these guidelines may result in legal action and other appropriate actions.",
        title: 'Recording, Confidentiality and Intellectual Property Notice',
        text1:
          "This meeting will be recorded and the knowledge shared in this meeting is the presenter's intellectual property.",
        text2:
          'Recording and capturing screenshots is strictly prohibited without the prior written permission of the presenter or Contenta360. Violation of these guidelines may result in legal action and other appropriate actions.',
      },

      notification: {
        captions: 'Auto live transcription enabled!',

        copy: {
          title: 'Success',
          message: 'Link copied',
        },
      },

      loading: 'Entering Session...',

      lobby: {
        title: 'Hello visitor, welcome!',
        text: 'Please enter your name to access the meeting',
        input: { placeholder: 'Type here...' },
        button: {
          send: 'Enter',
        },
      },

      preview: {
        disclaimer: {
          paragraph:
            'This meeting will be recorded. By participating, you agree to be recorded.<br/><br/>It is forbidden to print or photograph images of<br/>the meeting, a summary will be sent to the participants later.',
        },
        buttons: {
          tooltips: {
            audio: {
              select: 'Select Audio',
              start: 'Start Audio',
              end: 'End Audio',
            },
            camera: {
              select: 'Select Camera',
              start: 'Start Camera',
              end: 'End Camera',
            },
            screen: {
              select: 'Select Screen',
              share: 'Share Screen',
            },
            closeChat: 'Close chat',
            openChat: 'Open chat',
            liveTranscription: 'Live Transcription',
          },
          ready: 'Join the meeting',
        },
      },
      video: {
        notification: {
          endCall: {
            message: 'Meeting ended',
            description: 'This meeting has been ended by host',
          },
        },
        languages: {
          EN: 'English',
          ZH: 'Chinese (Simplified)',
          JA: 'Japanese',
          DE: 'German',
          FR: 'French',
          RU: 'Russian',
          PT: 'Portuguese',
          'PT-BR': 'Portuguese',
          ES: 'Spanish',
          KO: 'Korean',
          IT: 'Italian',
          EN1: 'Reserved',
          VI: 'Vietnamese',
          NL: 'Dutch',
          UK: 'Ukrainian',
          AR: 'Arabic',
          BN: 'Bengali',
          'ZH-HANT': 'Chinese (Traditional)',
          CS: 'Czech',
          ET: 'Estonian',
          FI: 'Finnish',
          EL: 'Greek',
          HE: 'Hebrew',
          HI: 'Hindi',
          HU: 'Hungarian',
          ID: 'Indonesian',
          MS: 'Malay',
          FA: 'Persian',
          PL: 'Polish',
          RO: 'Romanian',
          SV: 'Swedish',
          TA: 'Tamil',
          TE: 'Telugu',
          TL: 'Tagalog',
          TR: 'Turkish',
        },
        buttons: {
          endSession: 'End session',
          exitSession: 'Leave Session',
          exitingSession: 'Leaving...',
          tooltips: {
            audio: {
              select: 'Select Audio',
              start: 'Start Audio',
              end: 'End Audio',
            },
            camera: {
              select: 'Select Camera',
              start: 'Start Camera',
              end: 'End Camera',
            },
            shareScreen: {
              select: [
                'lock Screen',
                'One participant can share at a time',
                'multiple participants can share simultaneously',
              ],
            },
            recording: {
              start: 'start recording',
              pause: 'pause recording',
              resume: 'keep recording',
              status: 'record status',
              stop: 'stop recording',
            },
            chat: 'session chat',
            leave: 'End session',
          },
        },
      },
    },

    chat: {
      presentialTourData: 'Presential Tour Data',
      reportAdvisor: 'Report',
      startAChat: 'Start a chat',
      endedTour: 'Tour ended',
      noChatSelected: 'No chat selected',
      reportSuccess: 'Report sent successfully',
      messages: {
        you: 'You',
      },
      reportUserModal: {
        title: 'Report',
        description:
          'Select below, please, the reason for your report, and do not worry, it will be anonymous. Thank you in advance for helping Contenta360 to continue being a safe and healthy environment for our community.',
        extraDescription: 'If you wish, add a comment.',
        title1: 'Photo - Travel Advisor identity',
        title2: 'Harassment',
        title3: 'Prejudice or intolerance',
        description1: 'The Travel Advisor profile picture is outdated.',
        description2:
          'During the tour did you feel uncomfortable or harassed? In this case, your complaint needs to be detailed, use the space below for your impressions.',
        description3:
          'During the tour did you notice any action or speech that translates prejudice of any nature?',
      },
    },

    adminPages: {
      prices: {
        title: 'Price table',
        table: {
          columns: {
            minimum: 'Minimum',
            maximum: 'Maximum',
            percent: 'Percent',
            operation: 'Operation',
          },
          areYouSure:
            'Are you sure you want to cancel the edition of this item?',
          areYouSureDelete: 'Are you sure you want to delete this item?',
          addNewVariant: 'Add new variant',
        },
      },
    },

    creditCard: {
      addCreditCard: 'Add credit card',
      invalidCardName: 'Invalid card name',
      cardHolder: 'Name as it appears on the card',
      cardHolderPlaceholder: 'Name as it appears on the card',
      expiryDate: 'Expiration date',
      cvc: 'Security code',
      cardNumber: 'Card number',
      emptyCardNumber: 'Empty card number',
      invalidCardNumber: 'Invalid card number',
      emptyExpiryDate: 'Invalid expiration date',
      monthOutOfRange: 'The expiration month must be between 01 and 12',
      yearOutOfRange: 'The expiration year cannot be in the past',
      dateOutOfRange: 'The expiration date cannot be in the past',
      invalidExpiryDate: 'Invalid expiration date',
      emptyCVC: 'Invalid security code',
      invalidCVC: 'Invalid security code',
      saveCreditCard: 'Save card for future purchases',
      finishPayment: 'Finish payment',
      finishingPayment: 'Finishing payment...',
      membershipPayment: 'Membership Payment',
      editMembership: 'Edit Credit Card',
      reloadingPage: 'Reloading page...',
      edit: 'Edit card',
      save: 'Confirm',
      removeCard: {
        title: 'Remove registered card',
        paragraph1: 'Are you sure you want to delete this card?',
        paragraph2:
          'If you wish to use it in the future, you will need to fill in all the information again.',
        button: 'Remove card',
      },
      deactivateMembership: {
        title: 'Cancel subscription',
        paragraph1: 'Are you sure you want to cancel your subscription?',
        paragraph2:
          'Your account will be paused and you will not be able to make new calls, <strong>but you can consult the history whenever you want</strong>.',
        button: 'Cancel subscription',
        hasMeetingYetParagraph1:
          'Seems like you still have some <strong>appointments scheduled</strong> and therefore you will not be able to deactivate your subscription.',
        hasMeetingYetParagraph2:
          'If you have any questions, please contact us via email <a href="mailto:contactus@contenta360.com">contactus@contenta360.com</a>.',
        success: 'Subscription canceled successfully. Updating page...',
      },
    },

    aboutUsV2: {
      title: 'Contenta360: Personalized Travel Planning with Local Experts',

      whoWeAre: {
        title: 'Who We Are',
        p1: 'At Contenta360, we make online travel planning effortless and enjoyable by combining the convenience of technology with the authenticity of human connection. We believe that the best travel experiences come from personalized recommendations, offered by real local experts who know your destination inside out.',
        p2: 'Our platform connects travelers with licensed local tour guides and specialists through live 1:1 video chat, providing up-to-date, reliable travel advice tailored to your unique preferences. Whether you’re looking for hidden gems, expert insights into local culture, practical tips on transportation and safety, recommendations for authentic food experiences, advice on the best neighborhoods to stay in, or seasonal highlights, our hand-selected Travel Experts are ready to help.',
      },

      mission: {
        title: 'Our Mission',
        p1: 'Our mission is simple: to save travelers time and help them plan unforgettable trips with the help of local travel experts, ensuring they experience each destination like a local: authentic, personalized, and stress-free.',
        p2: 'In a world overloaded with generic travel content, we bring back the human touch to travel planning.',
      },

      difference: {
        title: 'The Contenta360 difference: what makes us unique?',
        realExperts:
          '<strong>Real Local Experts:</strong> All our Travel Experts are licensed tour guides or local specialists, carefully hand-picked, with first-hand knowledge of your destination.',
        personalizedRecommendations:
          '<strong>Personalized Recommendations:</strong> Every traveler is unique. That’s why you get custom advice that fits your traveler profile, travel style, interests, and budget.',
        saveTime:
          '<strong>Save Time, Travel Smarter:</strong> Skip hours of online research and get trusted recommendations directly from locals, saving you time and avoiding tourist traps.',
        videoChat:
          '<strong>1:1 Video Chat Convenience:</strong> Plan your trip face-to-face with a local expert, right from your home.',
      },

      humanTouch:
        '<strong>At Contenta360, travel planning is human again</strong> — helping you make confident decisions and discover unique experiences that only locals know about.',

      howItWorks: {
        title: 'How It Works',
        steps: {
          title: 'How It Works',
          1: {
            title: 'Step 1: Find Your Travel Expert',
            text: `Browse licensed local tour guides and travel specialists by destination and expertise. Whether you’re into food, culture, adventure, or discovering hidden gems, there’s a Travel Expert ready to help. Pro tip: Take a closer look at our Travel Experts' specialties, you’ll be amazed by the incredible range of topics they cover, from historic battlefields to film locations to music history, accessible travel, and so much more!`,
          },
          2: {
            title: 'Step 2: Book a Video Chat',
            text: 'Schedule a one-on-one online travel consultation to ask questions, get personalized advice, and plan your itinerary with expert guidance.',
          },
          3: {
            title: 'Step 3: Travel Confidently',
            text: 'With local knowledge in your pocket, you’re free to book your flights, hotels, and activities wherever you prefer, knowing you have reliable recommendations from a trusted local.',
          },
          4: {
            title:
              'Step 4: Complete Your Journey with a Private, Custom Guided Tour',
            text: 'Loved your Travel Expert? Make the most of your trip by booking a private, fully customized guided tour with the expert who already knows exactly what you’re looking for. There’s no better way to explore your destination than with a trusted local guide by your side, ready to unlock authentic experiences and hidden gems tailored just for you. <br />Seamless from planning to exploring, counting on local insights you won’t find anywhere else.',
          },
        },
      },

      founders: {
        title: 'Meet Our Founders',
        rosana: {
          bio: 'Rosana Grilli - Founder & Co-CEO',
          description:
            '<p>With over 30 years of experience in the global travel industry, Rosana knows first-hand the challenges travelers face when planning their trips online. As a former Director at JTB Group, she’s had a 360-degree view of the traveler’s journey, from planning to arrival, ensuring every detail is perfect.</p>' +
            '<p>When the Covid-19 pandemic halted global travel in 2020, Rosana saw an urgent need for a new way to connect travelers with trusted local experts — even when they couldn’t travel. That’s how Contenta360 was born: to empower travelers with human connection and personalized travel planning, long before they pack their bags.</p>' +
            '<strong>“Traveling is so much easier when you know someone at your destination.”</strong> Rosana',
        },
        leticia: {
          bio: 'Leticia Arzuaga - Co-Founder & CEO',
          description:
            '<p>Leticia brings her expertise in architecture, design, and accessibility to Contenta360, ensuring the platform is intuitive, inclusive, and beautiful. A passionate traveler herself, Leticia has spent years hosting friends and family around her hometown, Chicago. She’s dedicated to making travel planning easy and enjoyable for everyone, including those with accessibility needs, by working closely with inclusive travel partners.</p>',
        },
      },

      why: {
        title: 'Why Choose Contenta360?',
        humanExperience:
          '<strong>Human experience, not algorithms:</strong> skip the overwhelming search results and get real advice from local experts, not generic AI-generated content.',
        tailoredToYou:
          "<strong>Tailored to You:</strong> Whether you're a food lover, history buff, or adventure seeker, your travel plan reflects your passions.",
        saveTime:
          '<strong>Save time and enjoy more:</strong> Get expert answers in minutes, no more wasting hours scrolling through endless search results.',
        travelLikeLocal:
          '<strong>Travel Like a Local:</strong> Experience authentic places, not just tourist hotspots.',
        trustTransparency:
          '<strong>Trust and Transparency:</strong> You’ll know exactly who you’re talking to, with licensed professionals ready to help.',
      },
    },

    privacyPolicy: {
      title: 'Privacy Policy',
      content: `The privacy and security of our users' data are of utmost importance to Contenta360. This Privacy Policy describes how we collect, use, store, and protect the personal information of users of our platform. By using the Contenta360 platform, you agree to the terms and conditions of this Privacy Policy.

      <div class="terms__subcontainer">
      <h3>1. Information Collected</h3>
      <p>1.1 Registration Information: When creating an account on the Contenta360 platform, we collect personal information provided by you, such as name, email address, and password. You may also choose to log in using your social login credentials (e.g., Facebook or Google). This information is necessary for the creation and management of your account.</p>
      <p>1.2 Profile Information: To enhance your experience on the platform, you may choose to provide additional information to your profile, such as travel preferences, interests, and specialties. This information is optional and helps personalize recommendations of tour guides based on your preferences.</p>
      <p>1.3 Payment Information: When you make a payment on the Contenta360 platform, we collect payment information, such as credit card details or other payment methods used. This information is collected and processed through third-party payment service providers, who have their own terms and privacy policies.</p>
      <p>1.4 Communication Information: We may collect information from communications you have with us, including emails, chat messages, and other types of interactions on the platform. This information is necessary for customer support and to improve the quality of service.</p>
      <p>1.5 Usage Information: We automatically collect information about how you interact with the Contenta360 platform, including access data, activity logs, IP address, device and browser information, cookies, and similar technologies. This information is used for data analysis, security, personalization, and continuous improvement of the platform.</p>
      </div>


      <div class="terms__subcontainer">
      <h3>2. Use of Information</h3>
      <p>2.1 Service Provision: We use the collected information to provide and operate the Contenta360 platform, including creating user accounts, connecting travelers and tour guides, managing bookings, processing payments, and facilitating communication between the parties involved.</p>
      <p>2.2 Personalization and Recommendations: We use the information to personalize users' experience on the platform by providing tour guide recommendations based on the preferences and interests recorded in travelers' profiles.</p>
      <p>2.3 Communication: We use the contact information provided to send relevant notifications about bookings, payments, platform updates, and other service-related information. We may also send marketing communications if you have given prior consent to receive them. You can opt out of receiving marketing communications at any time.</p>
      <p>2.4 Service Improvement: We use the collected information to analyze and improve the quality of the Contenta360 platform, including enhancing features, security, performance, usability, and personalization.</p>
      </div>

      <div class="terms__subcontainer">
      <h3>3. Sharing of Information</h3>
      <p>3.1 With Tour Guides (Advisors): We share travelers' information, such as name, booking dates, and preferences, with the selected tour guides for the provision of contracted services. This is necessary to enable tour guides to provide the best possible experiences to travelers.</p>
      <p>3.2 Service Providers: We may share information with third-party service providers who assist us in operating the platform, such as payment processors, hosting services, data analytics, security services, and customer support. These service providers have limited access to personal information necessary to perform their functions and are obligated to maintain the confidentiality of such information.</p>
      <p>3.3 Legal Compliance and Rights Protection: We may disclose personal information when required by law, regulation, legal process, or government request, or when we believe in good faith that disclosure is necessary to protect our rights, safety, or property, as well as the rights, safety, and property of our users.</p>
      </div>

      <div class="terms__subcontainer">
      <h3>4. Data Security</h3>
      <p>We take data security very seriously. We implement appropriate technical, administrative, and physical measures to protect personal information against unauthorized access, misuse, alteration, orunauthorized disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of data.</p>
      </div>

      <div class="terms__subcontainer">
      <h3>5. Data Retention</h3>
      <p>We retain users' personal information for as long as necessary to provide the requested services and comply with legal obligations. After this period, the information will be deleted or anonymized in a manner that no longer identifies the user.</p>
      </div>

      <div class="terms__subcontainer">
      <h3>6. Your Rights</h3>
      <p>Users of the Contenta360 platform have the right to access, correct, update, or delete their personal information, as well as to limit or object to the processing of such information, in accordance with applicable data protection laws. To exercise these rights or if you have any questions or concerns about data privacy and security, please contact us through the provided contact information in the platform's contact section.</p>
      </div>

      <div class="terms__subcontainer">
      <h3>7. Changes to this Privacy Policy</h3>
      <p>We may update this Privacy Policy periodically to reflect current practices and policies of Contenta360. We recommend that you review this policy regularly to stay informed about how we handle users' personal information.</p>
      <p>Effective Date: This Privacy Policy was last updated on 05/22/2023 and is effective as of that date.</p>
      </div>`,
    },
    termsAndConditions: {
      title: 'Terms and Conditions of Use',
      content: `
      <p>Contenta360, a sole proprietorship, registered under CNPJ/MF number 42.458.840/0001-42, headquartered in Brazil, in the State of São Paulo, in the City of São Paulo, at Al. Lorena, 131, office 31, ZIP code 01424-000, is a private legal entity responsible for the platform hereinafter referred to as Contenta360.</p>
<p>Through these General Terms and Conditions of Use ("Terms"), Contenta360 presents to users ("Users") the essential conditions for using the services offered on the Platform.</p>
<p>By using the Platform or the services offered by Contenta360, Usuários accept and submit to the conditions of these Terms and the respective Privacy Policies.</p>

<div class="terms__subcontainer">
<h2>• Objective</h2>
<p>• Contenta360 is a marketplace platform for connecting travelers (also referred to as Users) and tourism destination experts (also referred to as Providers), providing this platform for scheduling and conducting online consulting sessions, as well as for payment of these sessions. The Contenta360 platform also offers the possibility of scheduling a package of hours for guided tours at the tourist destination.</p>
<p>• Therefore, Contenta360 is responsible solely for mediating between Users and Service Providers, so that Users can find, schedule, make payments, and conduct online sessions, as well as schedule and make payments for the package of hours for in-person tours.</p>
<p>• Contenta360 provides a virtual room on the platform, through the website www.contenta360.com, designed to facilitate travel consultancy through video conferencing between Providers and Users.</p>
<p>• Service Providers are solely and exclusively responsible for the truthfulness and accuracy of the information and content presented in the virtual session, as well as for their working methods. Contenta360 is not liable for cancellation, delay, change of date or time of the session with the Service Provider, or any other moral or material damage to the User, which is not caused by Contenta360.</p>
<p>• Users/Travelers, when using the services, must make the payment exclusively to Contenta360, and it is Contenta360's responsibility to transfer the payment to the Providers, according to the conditions agreed upon between the Providers and Contenta360. In other words, the payment for services provided by the Service Providers, in the form of online consulting, must occur exclusively through the Platform, in conjunction with the Stripe Payment System, via credit card or any other payment method provided by Contenta360. The use or realization of any other payment arrangement between the Service Provider and User when using Contenta360's services is prohibited.</p>
<p>• For security and service guarantee purposes, Contenta360 prohibits Users from making payments directly to Service Providers, such as through bank deposit or direct payment. Nevertheless, Contenta360 will not be responsible for any losses and damages suffered by the User due to acts attributable to the Provider, as the Provider is solely and exclusively responsible for their respective service provision. Contenta360 is only responsible for mediating the scheduling of the session and/or guided tour, as well as providing the tool for conducting these sessions between User and Service Provider, in addition to receiving and transferring the corresponding service fees.</p>
<p>• If the Service Provider suggests to the User the use of alternative payment methods that are not in accordance with these Terms of Use and Privacy Policy, the Service Provider should communicate this to Contenta360 so that appropriate measures can be taken.</p>
<p>• By proceeding with the payment for the scheduled sessions on the Platform, it is presumed that the User has read and accepted the Terms of Use and Privacy Policy of the Payment System, available at the time of login to the system.</p>
<p>• Contenta360 reserves the right to modify the Payment System used by the Platform, at its sole discretion and at any time, by notifying the Service Provider and the User in advance.</p>
<p>• By registering, the User may use all the services available on the Platform, declaring, therefore, to have read, understood, and accepted the Terms and Conditions of Use.</p>
</div>

<div class="terms__subcontainer">
<h2>• Ability to Register</h2>
<p>• The Platform's services are only available to individuals who are legally capable of personally performing civil acts. The use by individuals under 18 years old is subject to the presence of parents or individuals over 21 years old in the virtual room, in the same session. By accessing, using, or creating an account on Contenta360, the User assures that they are legally capable of personally performing civil acts and are aware of the legal penalties provided for in the applicable legislation.</p>
<p>• It is prohibited to create more than one account per User. In case of multiple accounts created by a single User, Contenta360 reserves the right, at its sole discretion and without the need for prior agreement or communication with Service Providers or Users, to disable all existing accounts and prevent any future registrations associated with them.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Objective</h2>
	<p>• Contenta360 is a marketplace platform for connecting travelers (also referred to as Users) and tourism destination experts (also referred to as Providers), providing this platform for scheduling and conducting online consulting sessions, as well as for payment of these sessions. The Contenta360 platform also offers the possibility of scheduling a package of hours for guided tours at the tourist destination.</p>
	<p>• Therefore, Contenta360 is solely responsible for mediating between Users and Service Providers, so that Users can find, schedule, make payments, and conduct online sessions, as well as a tool for scheduling and making payments for in-person guided tours.</p>
	<p>• Contenta360 provides a virtual room on the platform, through the website www.contenta360.com, developed to facilitate travel consulting through video conferences between Providers and Users.</p>
	<p>• Service Providers are solely and exclusively responsible for the accuracy and correctness of the information and content presented in the virtual session, as well as their working methods, and Contenta360 is exempt from liability for cancellation, delay, change of date or session time with the Service Provider, or any other moral or material damage to the User, for which it is not responsible.</p>
	<p>• Users/Travelers, when using the services, must make the payment exclusively to Contenta360, and it is the latter's responsibility to transfer the payment to the Providers, according to the conditions agreed upon between said Providers and Contenta360. In other words, the payment for services provided by the Service Provider, in the form of online consulting, must be made exclusively through the Platform in conjunction with the Stripe Payment System, via credit card, or any other payment method provided by Contenta360. The use or implementation of any other payment arrangement between the Service Provider and User when using the services of Contenta360 is prohibited.</p>
	<p>• For security and service guarantee purposes, Contenta360 prohibits Users from making direct payments to Service Providers, for example, through bank deposit or direct payment. In any case, Contenta360 will not be responsible for any losses and damages suffered by the User, attributable to the Provider, as the latter is solely and exclusively responsible for the respective service. Contenta360 is only responsible for intermediating the scheduling of the session and/or guided tour, as well as providing the tool for conducting these sessions between the User and the Service Provider, in addition to receiving and transferring the corresponding service fees.</p>
	<p>• If the Service Provider suggests alternative payment methods to the User, contrary to the present Terms of Use and Privacy Policy, they must notify Contenta360 so that appropriate measures can be taken.</p>
	<p>• By proceeding with the payment of scheduled sessions on the Platform, it is presumed that the User has read and accepted the Terms of Use and Privacy Policy of the Payment System, available at the time of login.</p>
	<p>• Contenta360 reserves the right to modify the Payment System used by the Platform, at its sole discretion and at any time, providing prior notice to the Service Provider and the User.</p>
	<p>• By registering, the User can use all the services available on the Platform, declaring that they have read, understood, and accepted the Terms and Conditions of Use.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Scheduling Online Sessions</h2>
	<p>• Contenta360 offers a scheduling and payment management service for the use of Service Provider services. It should be noted that Contenta360 has no control over the availability of Service Providers. Therefore, all information regarding date, time, and location is the sole responsibility of the Service Providers.</p>
	<p>• The registered User can search for Service Providers in Destinations and/or their tourist attractions, paying attention to the search criteria:</p>
    <div class="terms__subcontainer">
      <p>• Country</p>
      <p>• City</p>
      <p>• Specialty</p>
      <p>• Experience</p>
      <p>• Name</p>
    </div>
  <p>• Based on the selected search criteria, a list of Service Providers in Destinations and/or their tourist attractions will be presented. To confirm the availability and price of each 1-hour session, the User must be logged into the Platform. The criteria for ordering the Service Providers in Destinations and/or their tourist attractions, in the list mentioned in item 4.2., will be determined solely by Contenta360.</p>
  <p>• The list of Service Providers may be changed at any time at the sole discretion of Contenta360.</p>
  <p>• It should be noted that the link to access the virtual room will be sent to the email registered and associated with the User's account that made the booking.</p>
  <p>• Contenta360 is not responsible for any losses and damages suffered by the affected party (in case of absence from the corresponding session).</p>
  <p>• Before finalizing the booking, the User must choose one of the available payment methods on the platform and make the payment prior to the respective booking.</p>
  <p>• After confirming the booking, the chosen time slot will be unavailable to other Users, ensuring that there is no overlapping of virtual sessions for the same Service Provider at the same time.</p>
  <p>• Contenta360 Users and Service Providers will receive notifications via their registered emails, serving as reminders for the scheduled sessions. Non-receipt or non-reading of notifications, for any reason, does not exempt the User from the need to log into the room using the link received at the time of booking.</p>
  <p>• Contenta360 Service Providers and registered Users can access their bookings through a panel provided on the Platform, under "My Account" > "Active Bookings".</p>
  <p>• Any delays on the part of the User and/or Service Provider will be the sole responsibility of the party causing the delay. Therefore, Contenta360 is not responsible for any losses and damages suffered by the affected party due to the delay.</p>
  <p>• A delay of up to 7 (seven) minutes, whether by the Service Provider or the User, will be considered acceptable and tolerable. In the event of a delay by the User, regardless of the reasons justifying it, the session time will not be extended, meaning that the User will lose the corresponding time for the delay. If the Service Provider is late (more than 7 minutes), the session will be terminated, and in this case, the User may choose, at their sole discretion, to reschedule or receive a full refund of the amount paid.</p>
  <p>• Contenta360 does not recommend external communication between the Provider and the User using alternative means of communication, even for resolving doubts and confirming session times. For any communications, it is established that they must be carried out exclusively through the Contenta360 platform.</p>
</div>

<div class="terms__subcontainer">
  <h2>• Change Policy</h2>
	<p>• The User can change the session time with the Service Provider at no cost, as long as it is done with at least 48 hours in advance of the scheduled session. The User can also cancel the session and request a full refund of the amount paid, as long as it is done with more than 48 hours in advance.</p>
	<p>• If the User chooses to reschedule the session with the Service Provider, it will be necessary to log into the Platform and indicate a new chosen time based on the Service Provider's availability.</p>
	<p>• Within a period of less than 48 (forty-eight) hours, the User will lose the right to reschedule or receive a refund of the amount paid.</p>
	<p>• If the User makes a booking and does not show up at the scheduled time or requests cancellation after the allowed withdrawal period mentioned in item 5.1, the amount charged for the session with the Service Provider will be passed on to the Service Provider, and no amount will be refunded to the User, as it will be considered as the User's withdrawal from the session with the Service Provider.</p>
	<p>• If the User wishes to receive a refund of the total amount of the session (for cancellation with 48 hours or more in advance), it will be necessary to contact Contenta360 through the email contactus@contenta360.</p>
	<p>• If the User made the payment by credit card, the amount will be refunded within 2 (two) billing cycles, according to the billing cycle date and processing by the credit card issuer used by the User. If the payment was made via PIX or bank slip, the refund period will be up to 10 (ten) business days.</p>
	<p>• If the Service Provider, for any reason, needs to reschedule the session, they must contact the User through the Platform to indicate another available time. The User should respond, informing whether they agree to the rescheduling or if they wish to cancel the session with the Service Provider. If the User does not agree with the change in the schedule, Contenta360 may recommend another Service Provider or refund the full amount to the User, at the sole discretion of the User. If the User chooses another provider, they will be subject to the price of the corresponding session and should be refunded the difference (if the price is lower) or pay the difference (if the price of the new provider is higher than the credit).</p>
	<p>• In the event of the Service Provider's non-appearance - either in the Video Chat or in the scheduled in-person tour - the charged amount will be fully refunded to the User, who may, at their sole discretion, use the credit for booking with another Service Provider. If the amount charged by the new provider is lower than the credit, the difference should be refunded by Contenta360. If the price of the chosen provider is higher than the credit, the User must make the payment for the respective difference.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Evaluation of Service Providers by Users</h2>
	<p>• Contenta360 offers Users a tool to evaluate Service Providers in order to contribute to the quality of the services provided. After the session with the Service Provider, the User can evaluate them based on the following categories:</p>
	<div class="terms__subcontainer">
		<p>• Attention: refers to the attention provided by the Service Provider throughout the session.</p>
		<p>• Punctuality: Whether there was a delay in entering the room by the Service Provider.</p>
		<p>• Quality of shared content by the guide: usefulness and practical application.</p>
	</div>
<p>• In each category mentioned in item 6.1 above, the User can assign a rating ranging from 1 (one) to 5 (five) stars. Contenta360 emphasizes that such evaluation will be done according to the User's criteria, with the aim of assessing their perception of the session's quality.</p>
<p>• In addition to the evaluation mechanism provided in items 6.1 and 6.2, the platform will provide a field for the User to write a comment about the Service Provider, describing the experience in more detail, if desired.</p>
<p>• The User will be solely and exclusively responsible for the content of the comments and must adhere to the facts, describing their opinion clearly and honestly. The use of language that is: (i) indecent, (ii) prejudiced, (iii) disrespectful, (iv) discriminatory, (v) injurious, (vi) defamatory, and/or that in any way goes against dignity, honor, morality, integrity, or any other right, regardless of nationality, ethnicity, or religion, is prohibited. It is also prohibited to include data (messages, information, images) with subliminal content, or information that constitutes or may constitute a crime (or misdemeanor) or that can be understood as incitement to commit crimes (or misdemeanors). Furthermore, comments must not: (x) offend freedom of belief and religions, (xi) violate any law or be inappropriate, (xii) undermine public order, good customs, and/or any legal norm, (xiii) engage in electoral propaganda or express favorable or unfavorable opinions about political parties or candidates, and (xiv) violate Intellectual Property rights, especially copyright, trademark, and personality rights.</p>
<p>• The comments made by Users do not have a technical nature; their sole purpose is to present the User's personal perceptions during the session with the Service Provider.</p>
<p>• Contenta360 will not moderate the publication of comments in advance, and Users can publish content directly on the Platform without any interference from Contenta360. However, Contenta360 may, at its sole discretion, delete comments that are proven to be untrue or that are in violation of the conditions provided in this document, particularly item 6.4.</p>
</div>


<div class="terms__subcontainer">
	<h2>• User Responsibility</h2>
	<p>• The User is responsible and agrees to:</p>
	<div class="terms__subcontainer">
		<p>• Acknowledge that all accesses made to the Platform, using the login and password, are interpreted as having been made by the User themselves, without contestation;</p>
		<p>• Have devices, resources, and technological equipment with enabled antivirus and firewall, properly updated software, and adopt minimum security measures to access the Platform;</p>
		<p>• Configure their AntiSpam systems, similar filters, or message redirection settings in a way that does not interfere with the receipt of communications and materials from Contenta360, and no excuse will be accepted if they fail to access any email or electronic message due to the mentioned resources;</p>
		<p>• Respect all intellectual property rights owned by Contenta360;</p>
		<p>• In case of damages resulting from acts performed through access to the Platform or to third parties, assume all obligations to indemnify the injured party, taking the passive role in any legal action or administrative procedure and requesting the exclusion of Contenta360, fully bearing the related expenses and costs, and releasing Contenta360 from any prejudice and burden.</p>
	</div>
<p>• The functionalities of the Platform are offered as a service, granting no rights to the User over the software related to the Platform or its underlying IT structures.</p>
<p>• Contenta360 does not recommend that Users contact the Provider outside the respective Platform. In case of violation of this obligation, Contenta360 may ban the User and/or the Provider, without prior notice or notification, resulting in the cancellation of the corresponding Account(s), without prejudice to the User's and/or Provider's obligation to indemnify Contenta360 for any losses and damages.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Exemptions and Limitations of Liability of Contenta360</h2>
	<p>• Contenta360 respects and complies with all laws and regulations applicable to its activities and expects all its Users and Service Providers to do the same. However, Contenta360 does not guarantee and assumes no responsibility for the compliance of its Users and Service Providers with any laws or regulations. By using the Contenta360 Platform or Services, the User acknowledges, understands, and agrees to comply with all laws and regulations that they are subject to by provision or imposition of these same laws and regulations.</p>
	<p>• Contenta360 does not guarantee the accuracy or authenticity of the information and content provided by Users and Service Providers and has no obligation to verify or monitor them. However, Contenta360 reserves the right to do so, directly or through authorized suppliers or third parties, to ensure the quality of the Services provided and compliance with these Terms, or to comply with applicable law or an order from a competent authority. These verifications include, but are not limited to:</p>
		<div class="terms__subcontainer">
			<p>• verifying the accuracy, authenticity, and timeliness of the information provided;</p>
			<p>• checking criminal, credit, online reputation, or any other background information in order to prevent or hold the User or Service Provider liable for suspected acts detrimental to Contenta360 and/or any other Provider or User, as well as to assess the User's and/or Service Provider's ability or likelihood to fulfill their responsibilities to Contenta360;</p>
			<p>• accessing, viewing, and monitoring any and all content posted or transmitted by the User through the Platform or Services.</p>
	</div>
</div>

<div class="terms__subcontainer">
	<h2>• Evaluation of Service Providers by Users</h2>
	<p>• Contenta360 offers Users a tool to evaluate Service Providers in order to contribute to the quality of the services provided. After the session with the Service Provider, the User can evaluate them based on the following categories:</p>
	<div class="terms__subcontainer">
		<p>• Attention: refers to the attention provided by the Service Provider throughout the session.</p>
		<p>• Punctuality: Whether there was a delay in the Service Provider's entry into the room.</p>
		<p>• Quality of shared content by the guide: usefulness and practical application.</p>
	</div>
  <p>• For each category mentioned in item 6.1 above, the User can assign a rating ranging from 1 (one) to 5 (five) stars. Contenta360 emphasizes that such evaluation will be done according to the User's criteria, with the objective of verifying their perception regarding the quality of the session.</p>
  <p>• In addition to the evaluation mechanism provided in items 6.1 and 6.2, the platform will provide a field for the User to write a comment about the Provider, describing the experience in greater detail, if desired.</p>
  <p>• The User will be solely responsible for the content of the comments and must adhere to the facts, describing their opinion in a clear and honest manner. The use of the following language is prohibited: (i) indecent, (ii) prejudiced, (iii) disrespectful, (iv) discriminatory, (v) injurious, (vi) defamatory, and/or any language that in any way infringes upon dignity, honor, morals, integrity, or any other right, regardless of nationality, ethnicity, or religion, (viii) that contains subliminal data (messages, information, images), (ix) that contains data or information that constitutes or may constitute a crime (or misdemeanor) or that can be understood as incitement to commit crimes (or misdemeanors); (x) that offends freedom of belief and religions, (xi) that violates any law or is inappropriate, (xii) that contravenes public order, good morals, and/or any legal norm, (xiii) that promotes electioneering or discloses a favorable or unfavorable opinion about a party or candidate, and (xiv) that violates intellectual property rights, especially copyright, trademark, and personality rights.</p>
  <p>• The comments made by Users do not have a technical nature; their sole purpose is to present the User's personal perceptions during the session with the Provider.</p>
  <p>• Contenta360 will not perform any prior moderation of comments, allowing Users to publish content directly on the Platform without any interference from Contenta360. However, Contenta360 may, at its sole discretion, delete comments that are proven to be untrue or that are not in compliance with the conditions stipulated in this agreement, particularly item 6.4.</p>
</div>

<div class="terms__subcontainer">
	<h2>• User Responsibility</h2>
	<p>• The User is responsible and undertakes to:</p>
	<div class="terms__subcontainer">
		<p>• Acknowledge that all accesses made on the Platform using their login and password are interpreted as having been made by themselves, incontestably;</p>
		<p>• Have devices, resources, and technological equipment with enabled antivirus and firewall, properly updated software, and adopt minimum security measures to access the Platform;</p>
		<p>• Adjust their AntiSpam systems, similar filters, or message redirection settings in a way that does not interfere with the receipt of communications and materials from Contenta360. No excuse will be accepted if the User claims not to have received an email or electronic message due to the mentioned resources;</p>
		<p>• Respect all intellectual property rights owned by Contenta360;</p>
		<p>• In the event of damages resulting from acts performed through access to the Platform or to third parties, bear all obligations to indemnify the injured party, assuming the defendant's role in a legal action or administrative proceeding and requesting the exclusion of Contenta360, fully covering the expenses and costs related to legal proceedings, relieving Contenta360 from any losses and burdens.</p>
	</div>
python
Copy code
<p>• The functionalities of the Platform are offered as a service, granting the User no rights over the software related to the Platform or its supporting IT structures.</p>
<p>• Contenta360 does not recommend that Users contact the Service Provider outside the respective Platform. In case of violation of this obligation, Contenta360 may ban the User and/or the Service Provider, regardless of any notice or notification, resulting in the cancellation of the corresponding Account(s), without prejudice to the User's and/or Service Provider's duty to indemnify Contenta360 for any losses and damages.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Exemptions and Limitations of Liability of Contenta360</h2>
	<p>• Contenta360 respects and complies with all laws and regulations applicable to its activities and expects all its Users and Service Providers to do the same. However, Contenta360 does not guarantee and assumes no responsibility for the compliance of its Users and Service Providers with any laws or regulations. By using the Contenta360 Platform or Services, the User acknowledges, understands, and agrees to comply with all laws and regulations that they are subject to by provision or imposition of these same laws and regulations.</p>
	<p>• Contenta360 does not guarantee the accuracy or authenticity of the information and content provided by Users and Service Providers and has no obligation to verify or monitor them. However, Contenta360 reserves the right to do so, directly or through authorized suppliers or third parties, to ensure the quality of the Services provided and compliance with these Terms, or to comply with applicable law or an order from a competent authority. These verifications include, but are not limited to:</p>
		<div class="terms__subcontainer">
			<p>• verifying the accuracy, authenticity, and timeliness of the information provided;</p>
			<p>• checking criminal, credit, online reputation, or any other background information in order to prevent or hold the User or Service Provider liable for suspected acts detrimental to Contenta360 and/or any other Provider or User, as well as to assess the User's and/or Service Provider's ability or likelihood to fulfill their responsibilities to Contenta360;</p>
			<p>• accessing, viewing, and monitoring any and all content posted or transmitted by the User through the Platform or Services.</p>
	  </div>

  <p>• The User, by accepting these Terms of Use, expressly authorizes the verification to be carried out in any of the ways established above.</p>
<p>• Contenta360 may collect and/or store the messages and dialogues exchanged between the User and the Provider, solely for security and verification purposes. Contenta360 shall not be held responsible for any losses and damages arising from the leakage or misuse of such data and/or information, whether by the Provider or the User.</p>
<p>• Contenta360 only facilitates the intermediation between the User and the Provider, allowing Users to find desired professionals and schedule sessions with the Service Provider. Contenta360 does not endorse any Provider and bears no responsibility for any illegal or inappropriate conduct by the Provider, only being able to exclude them in the case of proven unlawful conduct or non-compliance with the respective terms and conditions of use.</p>
<p>• While Contenta360 curates the selection of Providers, it shall not be held liable for any false information provided by these Providers, only being able to ban them if there is proven falseness regarding their identity, education, qualifications, or background.</p>
<p>• At no time shall Contenta360 be held responsible for the actions and omissions of Providers, as it solely provides a tool for scheduling and conducting sessions.</p>
<p>• Considering that Contenta360 is not responsible for any act or omission of any Provider, the User acknowledges that, by using the Platform or Services, they agree that any legal action or civil liability for actions or omissions of Providers, individual Users, or other third parties shall be limited to a claim against the Provider, individual Users, or third parties that caused harm, and agree not to attempt to impose liability or pursue legal recourse against Contenta360 or its shareholders, representatives, managers, or employees in relation to such actions or omissions. The same commitment applies to all other parties involved in claims or actions.</p>
<p>• Considering that Contenta360 is not a party to the sessions that occur between Users and Providers, the responsibility for all resulting obligations, whether fiscal, consumer-related, or of any other nature, lies exclusively with the Users and Providers.</p>
<p>• In the event that Contenta360 is named as a defendant in a legal action or administrative proceeding attributed to the User, the User will be called to the process and shall bear all resulting costs.</p>
<p>• The User shall indemnify Contenta360, its subsidiaries, controlled or controlling companies, directors, administrators, employees, representatives, and employees against any claims brought by other Users, Providers, or third parties arising from acts and omissions attributable to the User, occurring during the use of the Platform or Services, and which constitute a breach of these Terms of Use and other Contenta360 policies made available on the Platform, including attorney's fees.</p>
<p>• Contenta360 shall not be held responsible for loss of profit or any other damages or losses of any kind that the User may suffer due to interactions and transactions carried out or not through Contenta360, provided that such damages were not directly caused by Contenta360. By using the Platform or Services, the User acknowledges, understands, and agrees that they are acting at their own risk.</p>
<p>• The Contenta360 Platform or Services may contain links to other websites. This does not imply any partnership, supervision, complicity, endorsement, or solidarity of Contenta360 towards these websites, their content, products, or services. By using the platform, the User acknowledges and agrees that Contenta360 is not responsible for the availability or accuracy of such websites and their respective resources, content, products, or services contained or made available through such websites or resources. The User assumes sole and full responsibility for all risks and any damages or losses arising from the use of any of these websites or their content, products, or services.</p>
<p>• No advice, guidance, or information, whether verbal or written, obtained from Contenta360 or through the Platform, Services, or Content, creates any warranty or liability that has not been expressly provided for in this agreement.</p>
<p>• Contenta360 is not responsible for:</p>
<div class="terms__subcontainer">
	<p>• Any defects, technical and/or operational issues (problems, bugs, and glitches), or improper functioning that occur on the Users' devices or third-party devices and result from the regular use of the Platform;</p>
	<p>• Any direct or indirect damage caused by third-party events that are not directly caused by Contenta360, such as hacker attacks, unforeseeable system failures, server failures, or internet connection failures, including actions by third parties involving malicious software such as viruses, Trojan horses, and others that may in any way damage the Users' equipment or connection during access or use of the Platform, as well as the transfer of data, files, images, or texts contained therein;</p>
	<p>• Users' navigation through external links contained on the Platform, and it is their responsibility to read the respective Terms of Use and Privacy Policy of the accessed portal;</p>
	<p>• Verification, control, approval, or guarantee of the adequacy or accuracy of the information or data provided in such links, and Contenta360 is not responsible for any damages, losses, or harm caused by visiting such websites. It is the responsibility of the interested party to verify the reliability of the information and data displayed before making any decisions or taking any actions;</p>
	<p>• Full and uninterrupted availability of the Platform, as its correct functioning depends on the access and data traffic between Users' computer terminals and servers owned or contracted by Contenta360. Contenta360 does not have any control over and is not responsible for any failures in the data traffic or access to the Platform caused by failures in the worldwide computer network or the network and telecommunication services provided by internet service providers;</p>
	<p>• System errors that may alter data or information regarding the services available on the Platform;</p>
	<p>• Reimbursement of Users for any expenses incurred, such as phone calls, data packages, SMS, messages, emails, mail, or any other costs resulting from contact with Contenta360 or any other Users, regardless of the reason.</p>
</div>
<p>• Users may not demand the availability of the Platform according to their own convenience. They also may not claim compensation or damages in case of technical problems that compromise the use of the Platform. If such unavailability affects the completion of a session, the paid amount will be refunded to the User.</p>
<p>• The eventual removal, blocking, or suspension of any content or functionality of the Platform due to a complaint should always be understood as a demonstration of good faith and an intention to seek amicable conflict resolution, never as an acknowledgment of guilt or any violation by Contenta360.</p>
<p>• Contenta360 reserves the right to assist and cooperate with any judicial authority or government agency, including providing user registration information if required.</p>
</div>


<div class="terms__subcontainer">
	<h2>• Intellectual Property</h2>
	<p>• The commercial use of the expression "Contenta360" as a trademark, trade name, or domain name; the contents of the screens related to the Contenta360 Platform or Services; and the programs, databases, logos, networks, files that allow the User to access the Platform and use their Account, are all the property of Contenta360 and are protected by international laws and treaties on copyright, trademarks, patents, models, and industrial designs. The improper use and total or partial reproduction of said items are prohibited, except with the express authorization of Contenta360.</p>
	<p>• The User agrees not to use, copy, adapt, modify, create derivative works, distribute, license, sell, transfer, publicly display, publicly perform, transmit, or exploit the Platform, Services, or Contents, except as expressly permitted in these Terms. The User also agrees not to remove, alter, or conceal any copyrights, trademarks, service marks, or other proprietary rights incorporated or accompanying the Platform, Services, or Contents.</p>
	<p>• Any interference, attempt, or activity that violates or contravenes the laws of intellectual property rights and/or the prohibitions set forth in these Terms of Use will make the User responsible and liable for the appropriate legal actions, as well as the sanctions provided herein, and will also be responsible for compensation for any damages caused.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Restrictions</h2>
	<p>• Any attempt, direct or indirect, successful or not, to perform any of the activities listed below is strictly prohibited. The identification of such intent will make the author, as well as their mandators, defenders, encouragers, and helpers, liable for the sanctions provided for in these Terms, the relevant legal actions, and the responsibility to compensate any parties affected by eventual damages caused. Such activities include, but are not limited to:</p>
		<div class="terms__subcontainer">
			<p>• Annoying, assaulting, slandering, defaming, persecuting, or harassing any other User, Provider, or Partners;</p>
			<p>• Infringing the rights of any person or entity, including, without limitation, intellectual property rights, privacy, publicity, or contractual rights;</p>
			<p>• Copying, storing, or in any way accessing any information contained in the Platform, Services, or Contents for purposes not expressly authorized by these Terms;</p>
			<p>• Using the Platform or Services to transmit, distribute, publish, or send any information regarding any other person or entity, including, without limitation, photographs of other people without their permission, personal contact or credit, debit, telephone card, or account numbers;</p>
			<p>• Manipulating values or any other information contained in the ads, the Platform, or the Services;</p>
			<p>• Using the Platform or Services for any commercial purpose or for purposes not expressly authorized by these Terms;</p>
			<p>• Interfering in transactions between other Users and/or Providers;</p>
			<p>• Using manual or automated software, devices, scripts, robots, or other means or processes to access, scrape, crawl, or spider any part of the Platform, Services, or Contents;</p>
			<p>• Modifying, copying, distributing, transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works, displaying on another webpage, using on any other website, transferring, or selling any information, software, user lists, databases, or other lists, products, or services provided by or obtained through Contenta360, including, but not limited to, practices involving screen scraping, database scraping, or any other activity for the purpose of obtaining user lists or other information;</p>
			<p>• Using automated scripts to collect information or otherwise interact with the Platform or Services;</p>
			<p>• Accessing, testing, tampering with, interfering with, or damaging the Platform or any system of Contenta360, Users, Providers, or third parties through the use of viruses, cancel bots, Trojan horses, harmful codes, flood pings, denial of service attacks, packet or IP spoofing, forged routing or email address information, or similar methods or technology;</p>
			<p>• Attempting to detect, scan, or test the vulnerability of any Contenta360 system or network or violate any security or authentication measures;</p>
			<p>• Evading, ignoring, removing, disabling, impairing, decoding, or circumventing any technological measure implemented by Contenta360 or any of the Contenta360 providers or any other third party (including another User) to protect the Platform, Services, or Contents;</p>
			<p>• Forgery of any TCP/IP header packet or any part of the header information in any publication, email, or newsgroup, or otherwise using the Platform, Services, or Contents to send altered, deceptive, or false identification source information;</p>
			<p>• Systematically retrieving data or other content from our Platform

 or Services to create or compile, directly or indirectly, in single or multiple downloads, the collection, compilation, database, directory, or similar, whether by manual methods, through the use of bots, crawlers, or spiders, or by any other means;</p>
			<p>• Attempting to decipher, decompile, disassemble, or reverse engineer any software used to provide the Platform, Services, or Contents;</p>
			<p>• Impersonating any person or entity, falsifying or altering oneself or one's affiliation with any person or entity;</p>
		</div>
</div>

<div class="terms__subcontainer">
	<h2>• Sanctions</h2>
	<p>• If any User fails to comply with any provision of these Terms and Additional Terms or engages in any activity that harms the Platform, Contenta360, Providers, or other Users, Contenta360 may, at its sole discretion, at any time and without prior notice or consent:</p>
	<div class="terms__subcontainer">
		<p>• Suspend or permanently cancel the User's Account and any and all access of such User to the Platform and Services.</p>
		<p>• Take appropriate actions to investigate and prosecute violations of any nature to the fullest extent of the law, which may involve or cooperate with judicial and law enforcement authorities.</p>
	</div>
</div>

<div class="terms__subcontainer">
	<h2>• Account Cancellation Policy</h2>
	<p>• Contenta360 may terminate these Terms, User Accounts, or Users' access to the Platform and Services at any time, without prior notice or consent, at its sole discretion. In this case, Contenta360 undertakes to take appropriate measures to avoid harming the Partner or Users.</p>
	<p>• The User may cancel their Contenta360 Account within the Platform itself, provided that they have no pending obligations to Contenta360 or Providers, including but not limited to: (i) open session with a Service Provider and (ii) pending balances to be redeemed.</p>
	<p>• In case of termination:</p>
	<div class="terms__subcontainer">
		<p>• The User will remain responsible for the payment of any amounts owed to Contenta360, Providers, or third parties;</p>
		<p>• Contenta360 will review any amounts legally owed to the User and pay them in a timely manner;</p>
		<p>• Contenta360 will not assume any responsibility for amounts owed by Users to Providers.</p>
	</div>

	<p>• In the event of canceling Your Account, Contenta360 will not immediately delete or return any Content you have submitted to the Platform, including but not limited to any opinions, comments, or testimonials, while respecting the rights to request the deletion of personal data, as set forth in these Terms of Use and the Platform's Privacy Policy. Contenta360 retains Users' and Providers' personal data only for the purposes for which they are collected and for compliance with legal and regulatory obligations.</p>
	<p>• The fact that Contenta360 fails to exercise or enforce any right or provision of these Terms does not constitute a waiver of the future application of that right or these Terms. Waiver of any right will only be effective if made in writing and signed by a duly authorized representative of Contenta360.</p>
</div>


	<div class="terms__subcontainer">
	<h2>• Contact and Reporting Channel</h2>
	<p>• Contenta360 provides its Users and Providers with a customer service channel for criticism, suggestions, complaints, and other reports that the User and/or Provider may deem appropriate, at the following address: www.contenta360.com/contact. When using this channel, the User or Provider must provide relevant information (name, address, phone number, and email) and describe the situation in detail, so that Contenta360 can assess any appropriate measures provided in these Terms.</p>
</div>

<div class="terms__subcontainer">
	<h2>• Notifications</h2>
	<p>• Any notifications or other communications permitted or required under this document, including modifications to these Terms, shall be in writing and delivered by Contenta360 (i) by posting on the Platform or (ii) via email to the email address provided by the User in their Account.</p>
	<p>• Any notifications or other communications directed to Contenta360 shall also be in writing and delivered through the available contact channels at the following address: www.contenta360.com/contact.</p>
	<p>• For notices sent via email, the date of receipt shall be considered the date on which such notification was transmitted.</p>
</div>


	<div class="terms__subcontainer">
	<h2>• Applicable Law and Jurisdiction</h2>
	<p>• All provisions of these Terms are governed by the laws in force in the Federative Republic of Brazil. For all matters relating to the interpretation, compliance, or any other issues related to these Terms, the parties expressly agree to the jurisdiction of the Court of São Paulo/SP.</p>
</div>`,
    },

    notTalkedMentor: {
      paragraph1: 'You don’t have any scheduled meetings yet.',
      paragraph2:
        'Our Travel Experts are here and ready to help you plan your trip!',
    },

    withoutMembership: {
      onlineAttend: 'Attend online',
      presentialAttend: 'Attend in-person',
      definePrice: 'Set your price',
      personalizeAgenda: 'Personalize your schedule',
      paragraph1:
        'Your registration has been approved to act as a Travel Expert on the Contenta360 portal.',
      paragraph2:
        'Sign up for the platform service provider monthly plan and start capturing customers today!',
      title: 'Welcome {ADVISOR_NAME}!',
      mensalPlan: 'per month',
      advisorPlan: 'Travel Expert Subscription',
      support24h: '24-hour technical support',
      subjectToTerms: 'Subject to ',
      termsAndConditions: 'Terms and Conditions',
      button: 'Subscribe now',
      nophoto: {
        title: 'Add a photo to your profile!',
        paragraph1:
          'This is an important step for the customer to identify you when they meet you in-person.',
      },
    },

    faq: {
      title: 'How can we help you?',
      searchPlaceholder: 'Search by keywords',
    },

    scheduledTours: {
      meetingPoint: 'Meeting point',
      newMeetingPoint: 'New meeting point',
      changeMeetingPoint: 'Change meeting point',
      errors: {
        meetingPointRejected:
          'Meeting point rejected by the traveler. Do you want to suggest a new point?',
      },
    },

    meetingResume: {
      tourSuggestions: 'Tour suggestions',
      onlineMeeting: 'Video Chat',
      presentialTour: 'In-Person tour',
      fillMeetingResume: 'Fill meeting summary',
      suggestionsForTour: 'Suggestions for the tour',
      transportationTips: 'Transportation tips',
      securityTips: 'Security tips',
      restaurantsAndBars: 'Restaurants/Bars',
      attractionsToBeVisited: 'Attractions to be visited',
      recommendedTours: 'Recommended tours',
      makeAMeetingResume: 'Make a meeting summary',
      dateTour: 'Date of the tour',
      startTour: 'Start tour',
      endTour: 'End tour',
      zipCode: 'Postal code',
      street: 'Street',
      number: 'Number',
      complement: 'Complement',
      referencePoint: 'Reference point',
      recommendationsFromYourTravelAdvisor:
        'Recommendations from your Travel Expert',
      tour1: 'Tour 1 - Describe here clearly a summary for tour suggestion',
      tour2: 'Tour 2 - Describe here clearly a summary for tour suggestion',
      resumeAboutTour: 'Make a summary about the tour',
      sendMeetingResume: 'Send summary',
      welcomeTextPresential: `Hello $TRAVELLER_NAME! I am very happy to receive you here in my city!`,
      welcomeTextOnline: `Hello $TRAVELLER_NAME! Thank you for our meeting. I leave here a summary of what we talked about:`,
    },
  },
};
