import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Card } from 'antd';

import { colors } from 'styles/themes/default';

import { comments } from 'constants/user-testemonials';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.scss';

const MAX_COMMENT_LENGTH = 170;

const getRandomComments = () => {
  return [...comments].sort(() => Math.random() - 0.5).slice(0, 3);
};

const Comments = () => {
  const { t } = useTranslation();

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);

  const isMobile = window.innerWidth <= 1024;

  return (
    <div className="comments__container">
      <h2>{t('home.comments.title')}</h2>

      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={isMobile ? 1 : 3}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 2,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        {getRandomComments().map(
          ({ name, comment, image, type, typeUpload, avatar }, index) => {
            const isExpanded = expandedIndex === index;
            const slicedComment = isExpanded
              ? comment
              : comment.length > MAX_COMMENT_LENGTH
              ? `${comment.slice(0, MAX_COMMENT_LENGTH)}...`
              : comment;

            return (
              <SwiperSlide key={index}>
                <Card>
                  {typeUpload === 'video' ? (
                    activeVideoIndex === index ? (
                      <div className="comments__video-wrapper">
                        <img
                          src={avatar}
                          alt=""
                          className="comments__video-blur-bg"
                        />
                        <video
                          className="comments__card-header comments__card-header--video"
                          src={image}
                          controls
                          autoPlay
                          muted
                          playsInline
                        />
                      </div>
                    ) : (
                      <div
                        className="comments__card-header comments__video-thumbnail"
                        onClick={() => setActiveVideoIndex(index)}
                      >
                        <img
                          src={avatar}
                          alt={`Thumbnail for ${name}'s video`}
                        />
                        <div className="comments__video-overlay" />
                        <div className="comments__play-button" />
                      </div>
                    )
                  ) : (
                    <div
                      className="comments__card-header"
                      style={{ backgroundImage: `url(${image})` }}
                    />
                  )}

                  <p className="comments__card-comment">{slicedComment}</p>

                  {comment.length > MAX_COMMENT_LENGTH && (
                    <div className="comments__card-action">
                      <a
                        href="#"
                        className="comments__card-footer-link"
                        onClick={(e) => {
                          e.preventDefault();
                          setExpandedIndex(isExpanded ? null : index);
                        }}
                      >
                        {isExpanded ? 'Show less' : 'Read more'}
                      </a>
                    </div>
                  )}

                  <div className="comments__card-user">
                    <img src={avatar} alt={name} />
                    <div>
                      <span className="comments__card-user-name">{name}</span>
                      <span
                        className={`comments__card-user-type comments__card-user-type--${type}`}
                      >
                        {type}
                      </span>
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
            );
          }
        )}
      </Swiper>

      <div className="swiper-button-container">
        <div className="swiper-button-prev">
          <ArrowLeftOutlined
            style={{ fontSize: '24px', color: colors.v2.primary[900] }}
          />
        </div>
        <div className="swiper-button-next">
          <ArrowRightOutlined
            style={{ fontSize: '24px', color: colors.v2.primary[900] }}
          />
        </div>
      </div>

      <div className="comments__footer">
        <Link to="/testimonials">
          <span className="comments__footer-link">
            {t('home.comments.buttonSeeMore')}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Comments;
